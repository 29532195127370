import {
  getAllRecords,
  getRecord,
  getRecords,
  insertRecord,
  updateRecord,
} from "@/utils/helpers/data";
import { getDentistsByUserId } from "@/data/Dentists";

export interface Clinic {
  id?: string;
  name?: string | null;
  mobile_number: string | null;
  created_at?: string | null;
  updated_at?: string | null;
}

/**
 * Creates a new clinic record in the database.
 * @param clinic - The clinic data to insert.
 * @returns The created clinic or null if insertion failed.
 * @throws Will throw an error if the insertion fails.
 */
export const createClinic = async (clinic: Clinic): Promise<Clinic | null> => {
  return insertRecord<Clinic>("clinics", clinic);
};

/**
 * Updates an existing clinic record in the database.
 * @param clinicId - The ID of the clinic to update.
 * @param clinic - The updated clinic data.
 * @returns The updated clinic or null if update failed.
 * @throws Will throw an error if the update fails.
 */
export const updateClinic = async ({
  clinicId,
  clinic,
}: {
  clinicId: string | null | undefined;
  clinic: Clinic;
}): Promise<Clinic | null> => {
  if (!clinicId) return null;
  return updateRecord<Clinic>("clinics", "id", clinicId, clinic);
};

/**
 * Retrieves a single clinic by its ID.
 * @param clinicId - The ID of the clinic to retrieve.
 * @returns The requested clinic or null if not found.
 * @throws Will throw an error if the retrieval fails.
 */
export const getClinic = async (
  clinicId: string | null | undefined,
): Promise<Clinic | null> => {
  if (!clinicId) return null;
  return getRecord<Clinic>("clinics", "id", clinicId);
};

/**
 * Retrieves a single clinic by its mobile number.
 * @param mobileNumber - The mobile number of the clinic to retrieve.
 * @returns The requested clinic or null if not found.
 * @throws Will throw an error if the retrieval fails.
 */
export const getClinicByMobileNumber = async (
  mobileNumber: string | null | undefined,
): Promise<Clinic | null> => {
  if (!mobileNumber) return null;
  return getRecord<Clinic>("clinics", "mobile_number", mobileNumber);
};

/**
 * Retrieves all clinics from the database.
 * @returns An array of all clinics.
 * @throws Will throw an error if the retrieval fails.
 */
export const getAllClinics = async (): Promise<Clinic[]> => {
  return getAllRecords<Clinic>("clinics");
};

/**
 * Retrieves clinics associated with a user ID.
 * @param userId - The ID of the user.
 * @returns An array of clinics associated with the given user ID.
 * @throws Will throw an error if the retrieval fails.
 */
export const getClinicsByUserId = async (
  userId: string | null | undefined,
): Promise<Clinic[]> => {
  if (!userId) return [];

  const dentists = await getDentistsByUserId(userId);
  const clinicIds = dentists
    .map((dentist) => dentist.clinic_id)
    .filter((id) => id !== undefined) as string[];

  if (clinicIds.length === 0) return [];

  const clinics: Clinic[] = [];
  for (const clinicId of clinicIds) {
    const clinicRecords = await getRecords<Clinic>(
      "clinics",
      "id",
      clinicId,
    );
    clinics.push(...clinicRecords);
  }

  return clinics;
};
