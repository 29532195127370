import { IonButton, IonIcon, IonInput } from '@ionic/react';
import React, {
  KeyboardEvent,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';

import ButtonContainer from '@/components/common/buttonContainer/ButtonContainer';
import Spacer from '@/components/common/spacer/Spacer';
import { useGetStarted } from '@/contexts/GetStartedContext';
import useToast from '@/hooks/useToast';
import { MOBILE_NUMBER_LENGTH } from '@/utils/constants';
import { arrowForwardCircleOutline } from 'ionicons/icons';

import './MobileNumber.css';

const MobileNumber: React.FC = () => {
  const { mobileNumber, setMobileNumber, sendOtp } = useGetStarted();
  const displayToast = useToast();
  const [hasError, setHasError] = useState<boolean>(false);
  const input = useRef<HTMLIonInputElement>(null);

  const copy = {
    subHeader: 'ENTER YOUR MOBILE NUMBER',
    header: (
      <>
        What's your <span>mobile</span> number?
      </>
    ),
    message: 'An OTP will be sent to your mobile number for verification.',
    buttonText: 'Next',
    countryCode: '+63'
  };

  const onInput = useCallback(
    (event: CustomEvent) => {
      setMobileNumber(event.detail.value!);
      setHasError(false);
    },
    [setMobileNumber]
  );

  const onKeyDown = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      onNext();
    }
  }, []);

  const onNext = useCallback(() => {
    if (
      mobileNumber &&
      mobileNumber.length === MOBILE_NUMBER_LENGTH &&
      /^\d+$/.test(mobileNumber)
    ) {
      setHasError(false);
      sendOtp();
    } else {
      setHasError(true);
      displayToast({
        message: 'Please enter a valid mobile number.',
        duration: 5000,
        position: 'bottom',
        positionAnchor: 'nextButton'
      });
    }
  }, [mobileNumber, sendOtp]);

  useEffect(() => {
    setTimeout(() => {
      input.current?.setFocus();
    }, 240);
  }, []);

  return (
    <div id="mobileNumber">
      <Spacer top={32} bottom={24}>
        <h4 id="subHeader">{copy.subHeader}</h4>
        <h1 id="header">{copy.header}</h1>
        <p id="message">{copy.message}</p>
      </Spacer>

      <Spacer top={16} bottom={102}>
        <div id="form">
          <div className={`input ${hasError ? 'error' : ''}`}>
            <IonInput
              ref={input}
              type="tel"
              label="Mobile Number"
              labelPlacement="stacked"
              value={mobileNumber}
              placeholder="9---------"
              onIonInput={onInput}
              onKeyDown={onKeyDown}
              maxlength={10}
            >
              <div slot="start">{copy.countryCode}</div>
            </IonInput>
          </div>
        </div>
      </Spacer>
      <ButtonContainer>
        <IonButton
          id="nextButton"
          onClick={onNext}
          expand="block"
          fill="solid"
          size="default"
          color="primary"
          strong={true}
        >
          <IonIcon slot="start" icon={arrowForwardCircleOutline}></IonIcon>
          {copy.buttonText}
        </IonButton>
      </ButtonContainer>
    </div>
  );
};

export default MobileNumber;
