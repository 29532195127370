import {
  IonButton,
  IonCheckbox,
  IonContent,
  IonIcon,
  IonLoading,
  IonModal,
  IonSelect,
  IonSelectOption
} from '@ionic/react';
import React, { useCallback, useEffect, useState } from 'react';

import ButtonContainer from '@/components/common/buttonContainer/ButtonContainer';
import Logo from '@/components/common/logo/Logo';
import Padding from '@/components/common/padding/Padding';
import Spacer from '@/components/common/spacer/Spacer';
import { useDentist } from '@/contexts/DentistContext';
import { DentistHour } from '@/data/DentistHours';
import useToast from '@/hooks/useToast';
import { DAY_NAMES_ARRAY } from '@/utils/constants';
import { arrowBackOutline, checkmarkCircleOutline } from 'ionicons/icons';
import moment from 'moment';

import './UpdateWorkingHours.css';

interface UpdateWorkingHoursProps {
  isOpen: boolean;
  onClose: () => void;
  presentingElement?: HTMLElement | undefined;
}

const UpdateWorkingHours: React.FC<UpdateWorkingHoursProps> = ({
  isOpen,
  onClose,
  presentingElement
}) => {
  const displayToast = useToast();
  const { dentistHours, updateHours } = useDentist();

  const [loading, setLoading] = useState<boolean>(false);

  const copy = {
    subHeader: 'WORKING HOURS',
    header: (
      <>
        Manage your <span>working</span> hours
      </>
    ),
    message:
      'Please check all the days you are working and specify the working hours for each day.'
  };

  const initialHours = Array.from({ length: 7 }, (_, day) => ({
    day,
    start_time: null,
    end_time: null,
    open: false
  }));

  const [hours, setHours] = useState<DentistHour[]>(initialHours);

  useEffect(() => {
    if (dentistHours.length > 0) {
      setHours((prevHours) =>
        prevHours.map((hour) => {
          const matchingHour = dentistHours.find((dh) => dh.day === hour.day);
          return matchingHour ? { ...hour, ...matchingHour } : hour;
        })
      );
    }
  }, [dentistHours]);

  const timeOptions = Array.from({ length: 48 }, (_, i) => {
    const hours = String(Math.floor((i + 8) / 2) % 24).padStart(2, '0');
    const minutes = (i + 12) % 2 === 0 ? '00' : '30';
    return `${hours}:${minutes}`;
  });

  const handleTimeChange = useCallback(
    (day: number, type: 'start_time' | 'end_time', value: string) => {
      setHours((prevHours) =>
        prevHours.map((hour) =>
          hour.day === day || !hour.open ? { ...hour, [type]: value } : hour
        )
      );
    },
    []
  );

  const handleCheckboxChange = useCallback((day: number, open: boolean) => {
    setHours((prevHours) =>
      prevHours.map((hour) => (hour.day === day ? { ...hour, open } : hour))
    );
  }, []);

  const onNext = useCallback(async () => {
    const validHours = hours.filter(
      (hour) =>
        !hour.open ||
        (hour.start_time &&
          hour.end_time &&
          moment(hour.end_time, 'HH:mm').isAfter(
            moment(hour.start_time, 'HH:mm')
          ))
    );
    if (validHours.length > 0) {
      try {
        setLoading(true);
        await updateHours(validHours);
        setLoading(false);
        onClose();
      } catch {
        setLoading(false);
        displayToast({
          message: 'Something went wrong. Please try again.',
          duration: 3000,
          position: 'bottom',
          positionAnchor: 'tabBar'
        });
      }
    } else {
      displayToast({
        message: 'Please add at least one valid working day and specify hours.',
        duration: 5000,
        position: 'bottom',
        positionAnchor: 'tabBar'
      });
    }
  }, [hours, onClose]);

  useEffect(() => {
    return () => {
      setLoading(false);
    };
  }, []);

  return (
    <IonModal
      isOpen={isOpen}
      onDidDismiss={onClose}
      presentingElement={presentingElement}
    >
      <IonContent>
        <Padding withSafeArea={!presentingElement}>
          <div id="updateWorkingHours">
            <Logo leftIcon={arrowBackOutline} onClickLeftIcon={onClose} />
            <Spacer top={0} bottom={24}>
              <h4 id="subHeader">{copy.subHeader}</h4>
              <h1 id="header">{copy.header}</h1>
              <p id="message">{copy.message}</p>
            </Spacer>

            <Spacer bottom={124}>
              <div id="workingDays">
                {hours.map((hour) => (
                  <div key={hour.day} className="workingDay">
                    <IonCheckbox
                      className="day"
                      checked={hour.open}
                      onIonChange={(e: CustomEvent) =>
                        handleCheckboxChange(hour.day, e.detail.checked)
                      }
                    >
                      <p className="dayLabel">{DAY_NAMES_ARRAY[hour.day]}</p>
                    </IonCheckbox>
                    {hour.open && (
                      <>
                        <Spacer top={12} />
                        <div className="workingHours">
                          <div className="input">
                            <IonSelect
                              label="Start Time"
                              labelPlacement="stacked"
                              value={hour.start_time}
                              compareWith={(a: string, b: string) =>
                                moment(a, 'HH:mm').isSame(moment(b, 'HH:mm'))
                              }
                              placeholder="Select a time"
                              onIonChange={(e: CustomEvent) =>
                                handleTimeChange(
                                  hour.day,
                                  'start_time',
                                  e.detail.value
                                )
                              }
                            >
                              {timeOptions.map((time) => (
                                <IonSelectOption key={time} value={time}>
                                  {moment(time, 'HH:mm').format('h:mm A')}
                                </IonSelectOption>
                              ))}
                            </IonSelect>
                          </div>
                          <div className="input">
                            <IonSelect
                              label="End Time"
                              labelPlacement="stacked"
                              value={hour.end_time}
                              compareWith={(a: string, b: string) =>
                                moment(a, 'HH:mm').isSame(moment(b, 'HH:mm'))
                              }
                              placeholder="Select a time"
                              onIonChange={(e: CustomEvent) =>
                                handleTimeChange(
                                  hour.day,
                                  'end_time',
                                  e.detail.value
                                )
                              }
                            >
                              {timeOptions
                                .filter(
                                  (time) =>
                                    !hour.start_time ||
                                    moment(time, 'HH:mm').isAfter(
                                      moment(hour.start_time, 'HH:mm')
                                    )
                                )
                                .map((time) => (
                                  <IonSelectOption key={time} value={time}>
                                    {moment(time, 'HH:mm').format('h:mm A')}
                                  </IonSelectOption>
                                ))}
                            </IonSelect>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                ))}
              </div>
            </Spacer>

            <ButtonContainer>
              <IonButton
                id="nextButton"
                onClick={onNext}
                expand="block"
                fill="solid"
                size="default"
                color="primary"
                strong={true}
                disabled={loading}
              >
                <IonIcon slot="start" icon={checkmarkCircleOutline}></IonIcon>
                Update
              </IonButton>
            </ButtonContainer>
          </div>
        </Padding>
        <IonLoading isOpen={loading} message="Loading..." spinner="crescent" />
      </IonContent>
    </IonModal>
  );
};

export default UpdateWorkingHours;
