import { NavContext } from '@ionic/react';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';

import { useAuth } from '@/contexts/AuthenticationContext';
import { useDentist } from '@/contexts/DentistContext';
import { useTracker } from '@/contexts/TrackerContext';
import { Clinic, createClinic, getClinic } from '@/data/Clinics';
import { getDentistsByUserId } from '@/data/Dentists';
import useToast from '@/hooks/useToast';
import { GET_STARTED_STEPS } from '@/utils/constants';
import { ROUTES } from '@/utils/routes';
import { supabase } from '@/utils/supabase';

interface GetStartedContextProps {
  loading: boolean;
  currentStep: string;
  setCurrentStep: React.Dispatch<React.SetStateAction<string>>;
  goBack: () => void;
  clinicName: string | null;
  setClinicName: React.Dispatch<React.SetStateAction<string | null>>;
  mobileNumber: string | null;
  setMobileNumber: React.Dispatch<React.SetStateAction<string | null>>;
  sendOtp: () => Promise<void>;
  resendOtp: () => Promise<void>;
  verifyOtp: (otp: string) => Promise<void>;
}

const GetStartedContext = createContext<GetStartedContextProps | undefined>(
  undefined
);

interface GetStartedProviderProps {
  children: React.ReactNode;
}

export const GetStartedProvider: React.FC<GetStartedProviderProps> = ({
  children
}) => {
  const { navigate, goBack } = useContext(NavContext);
  const { authenticated, user, signIn } = useAuth();
  const { selectDentist } = useDentist();
  const { captureEvent } = useTracker();
  const displayToast = useToast();

  const [loading, setLoading] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<string>(
    GET_STARTED_STEPS.CLINIC_NAME
  );
  const [clinicName, setClinicName] = useState<string | null>(null);
  const [mobileNumber, setMobileNumber] = useState<string | null>(null);

  const onDone = useCallback(async () => {
    selectDentist(null);
    navigate(ROUTES.SELECT_DENTIST);
  }, [navigate, selectDentist]);

  const sendOtp = useCallback(async () => {
    try {
      setLoading(true);
      if (
        mobileNumber &&
        authenticated &&
        `63${mobileNumber}` === user?.phone
      ) {
        displayToast({
          message: 'Skipped verification.',
          duration: 2400,
          position: 'bottom'
        });

        await signIn(false);
        await onDone();
        setLoading(false);
      } else {
        const { error } = await supabase.functions.invoke('otp', {
          body: {
            operation: 'send',
            payload: {
              phone_number: `63${mobileNumber}`
            }
          }
        });

        if (error) {
          throw new Error(`Error sending OTP: ${error.message}`);
        }

        setLoading(false);
        setCurrentStep(GET_STARTED_STEPS.VERIFICATION);
      }
    } catch (error: any) {
      setLoading(false);
      displayToast({
        message: error.message,
        duration: 5000,
        position: 'bottom'
      });
    }
  }, [mobileNumber, authenticated, user, signIn, onDone]);

  const resendOtp = useCallback(async () => {
    setLoading(true);
    await supabase.functions.invoke('otp', {
      body: {
        operation: 'resend',
        payload: {
          phone_number: `63${mobileNumber}`
        }
      }
    });
    setLoading(false);
  }, [mobileNumber]);

  const verifyOtp = useCallback(
    async (otp: string) => {
      try {
        setLoading(true);
        const {
          data: { user },
          error
        } = await supabase.auth.verifyOtp({
          phone: `63${mobileNumber}`,
          token: otp,
          type: 'sms'
        });

        if (error) {
          throw new Error(`Error verifying OTP: ${error.message}`);
        }

        if (!user) {
          throw new Error('Error verifying OTP: No user found.');
        }

        const existingClinic = await getClinic(user.id);
        if (!existingClinic) {
          const existingDentists = await getDentistsByUserId(user.id);
          if (existingDentists.length === 0 && clinicName && mobileNumber) {
            const newClinic = await createClinic({
              id: user.id,
              name: clinicName,
              mobile_number: mobileNumber
            } as Clinic);

            if (!newClinic) {
              throw new Error('Error creating clinic.');
            }

            captureEvent({
              event: 'user signed up',
              properties: {
                user_id: user.id,
                clinic_name: clinicName,
                mobile_number: mobileNumber
              }
            });
          }
        }

        await signIn(false);
        await onDone();
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
        displayToast({
          message: error.message,
          duration: 5000,
          position: 'bottom'
        });
      }
    },
    [mobileNumber, clinicName, signIn, onDone]
  );

  useEffect(() => {
    return () => {
      setLoading(false);
    };
  }, []);

  return (
    <GetStartedContext.Provider
      value={{
        loading,
        currentStep,
        setCurrentStep,
        goBack,
        clinicName,
        setClinicName,
        mobileNumber,
        setMobileNumber,
        sendOtp,
        resendOtp,
        verifyOtp
      }}
    >
      {children}
    </GetStartedContext.Provider>
  );
};

export const useGetStarted = () => {
  const context = useContext(GetStartedContext);
  if (context === undefined) {
    throw new Error('useGetStarted must be used within a GetStartedProvider');
  }
  return context;
};
