import { IonLoading, useIonActionSheet } from '@ionic/react';
import React, { useCallback, useMemo, useState } from 'react';

import { Dialog } from '@capacitor/dialog';

import TimelineItem from '@/components/dashboard/calendar/timeline/timelineItem/TimelineItem';
import TimelinePlaceholder from '@/components/dashboard/calendar/timeline/timelinePlaceholder/TimelinePlaceholder';
import { useClinic } from '@/contexts/ClinicContext';
import { useDentist } from '@/contexts/DentistContext';
import { useRequests } from '@/contexts/RequestsContext';
import {
  Request,
  cancelRequest,
  rescheduleRequest,
  updateAttendanceStatus
} from '@/data/Requests';
import useToast from '@/hooks/useToast';
import {
  ACCESS_TYPES,
  ATTENDANCE_STATUS_OPTIONS,
  OFF_DUTY_PATIENT_ID,
  REQUEST_ACTIONS,
  REQUEST_STATUS_OPTIONS
} from '@/utils/constants';
import { formatName } from '@/utils/helpers/formatName';
import { RequestAction } from '@/utils/types';
import moment from 'moment';

import './Timeline.css';

interface TimelineProps {
  requests: Request[];
  loading?: boolean;
}

const Timeline: React.FC<TimelineProps> = ({ requests, loading }) => {
  const [present] = useIonActionSheet();
  const displayToast = useToast();
  const { onUpdateStatus, onUpdateAttendanceStatus } = useRequests();
  const { selectedDentist: dentist } = useDentist();
  const { isClinic } = useClinic();

  const [updating, setUpdating] = useState<boolean>(false);

  const handleAction = useCallback(
    async ({
      request,
      action,
      confirm
    }: {
      request: Request;
      action: RequestAction;
      confirm?: boolean;
    }) => {
      const isOffDuty = request.patient_id === OFF_DUTY_PATIENT_ID;

      if (confirm) {
        const { value: confirmed } = await Dialog.confirm({
          title: 'Confirm',
          message: isOffDuty
            ? 'Are you sure you want to remove the off-duty status?'
            : `Are you sure you want to ${action.toUpperCase()} your appointment with ${formatName(request.patient?.first_name)}?`,
          okButtonTitle: 'Yes'
        });

        if (!confirmed) return;
      }

      setUpdating(true);
      const actionMap = {
        [REQUEST_ACTIONS.RESCHEDULE]: {
          requestFunc: rescheduleRequest,
          status: REQUEST_STATUS_OPTIONS.RESCHEDULED,
          message:
            'Appointment rescheduled. A new booking link has been sent to your patient for rescheduling.'
        },
        [REQUEST_ACTIONS.CANCEL]: {
          requestFunc: cancelRequest,
          status: REQUEST_STATUS_OPTIONS.CANCELLED,
          message: isOffDuty
            ? 'Removed off-duty status.'
            : 'Appointment cancelled. A new booking link has been sent to your patient for rescheduling.'
        },
        [REQUEST_ACTIONS.MARK_AS_LATE]: {
          requestFunc: updateAttendanceStatus,
          status:
            request.attendance_status === ATTENDANCE_STATUS_OPTIONS.LATE
              ? ATTENDANCE_STATUS_OPTIONS.DEFAULT
              : ATTENDANCE_STATUS_OPTIONS.LATE,
          message:
            request.attendance_status === ATTENDANCE_STATUS_OPTIONS.LATE
              ? 'Removed late status.'
              : 'Appointment marked as late.'
        },
        [REQUEST_ACTIONS.MARK_AS_NO_SHOW]: {
          requestFunc: updateAttendanceStatus,
          status:
            request.attendance_status === ATTENDANCE_STATUS_OPTIONS.NO_SHOW
              ? ATTENDANCE_STATUS_OPTIONS.DEFAULT
              : ATTENDANCE_STATUS_OPTIONS.NO_SHOW,
          message:
            request.attendance_status === ATTENDANCE_STATUS_OPTIONS.NO_SHOW
              ? 'Removed no-show status.'
              : 'Appointment marked as no-show.'
        }
      };

      const { requestFunc, status, message } = actionMap[action];
      if (
        action === REQUEST_ACTIONS.CANCEL ||
        action === REQUEST_ACTIONS.RESCHEDULE
      ) {
        if (isOffDuty) {
          await requestFunc(request.id!);
          onUpdateStatus(request, status);
          return;
        }

        const { value: reason, cancelled } = await Dialog.prompt({
          title: `Reason for ${action}`,
          message: 'Please provide a reason or any additional notes',
          inputPlaceholder: 'Enter reason or notes (optional)'
        });
        if (cancelled) return;

        await requestFunc(request.id!, reason === '' ? null : reason);
        onUpdateStatus(request, status);
      } else if (
        action === REQUEST_ACTIONS.MARK_AS_LATE ||
        action === REQUEST_ACTIONS.MARK_AS_NO_SHOW
      ) {
        await requestFunc(request.id!, status);
        onUpdateAttendanceStatus(request, status);
      } else {
        await requestFunc(request.id!);
      }
      displayToast({
        message,
        duration: 5000,
        position: 'bottom',
        positionAnchor: 'tabBar'
      });
      setUpdating(false);
    },
    [onUpdateStatus, onUpdateAttendanceStatus]
  );

  const hasAccess = useMemo(() => {
    return isClinic || dentist?.access === ACCESS_TYPES.EDIT;
  }, [isClinic, dentist?.access]);

  const onClick = (request: Request, isPast?: boolean) => {
    if (!hasAccess) return;

    const isOffDuty = request.patient_id === OFF_DUTY_PATIENT_ID;

    present({
      header: `${formatName(request.patient?.first_name)}${isOffDuty ? '-' : ' '}${formatName(request.patient?.last_name)}`,
      subHeader: request.purpose || '',
      buttons: [
        ...(isPast && !isOffDuty
          ? [
              {
                text:
                  request.attendance_status === ATTENDANCE_STATUS_OPTIONS.LATE
                    ? 'Remove Late Status'
                    : 'Mark as Late',
                handler: () =>
                  handleAction({
                    request,
                    action: REQUEST_ACTIONS.MARK_AS_LATE
                  })
              },
              {
                text:
                  request.attendance_status ===
                  ATTENDANCE_STATUS_OPTIONS.NO_SHOW
                    ? 'Remove No-Show Status'
                    : 'Mark as No-Show',
                handler: () =>
                  handleAction({
                    request,
                    action: REQUEST_ACTIONS.MARK_AS_NO_SHOW
                  })
              }
            ]
          : []),
        {
          text: isOffDuty ? 'Remove Off-Duty Status' : 'Cancel Appointment',
          role: 'destructive',
          handler: () =>
            handleAction({
              request,
              action: REQUEST_ACTIONS.CANCEL,
              confirm: true
            })
        },
        ...(isOffDuty
          ? []
          : [
              {
                text: 'Reschedule',
                handler: () =>
                  handleAction({
                    request,
                    action: REQUEST_ACTIONS.RESCHEDULE,
                    confirm: true
                  })
              }
            ]),
        {
          text: 'Nevermind',
          role: 'cancel',
          data: { action: 'cancel' }
        }
      ]
    });
  };

  const renderTimelinePlaceholders = () => {
    return (
      <>
        {[{ isFirst: true }, {}, { isLast: true }].map((props, index) => (
          <TimelinePlaceholder key={index} {...props} />
        ))}
      </>
    );
  };

  const renderTimelineItems = () => {
    return requests.map((request, index) => {
      const isLast = index === requests.length - 1;
      const isDifferentTime =
        !isLast &&
        moment(request.end_time, 'HH:mm').isBefore(
          moment(requests[index + 1].start_time, 'HH:mm')
        );
      const isOverlappedStartTime =
        index > 0 &&
        moment(request.start_time, 'HH:mm').isSameOrBefore(
          moment(requests[index - 1].end_time, 'HH:mm')
        );

      return (
        <TimelineItem
          key={request.id}
          request={request}
          onClick={onClick}
          isLast={isLast}
          isDifferentTime={isDifferentTime}
          isOverlappedStartTime={isOverlappedStartTime}
        />
      );
    });
  };

  return (
    <div className="timeline">
      {loading ? renderTimelinePlaceholders() : renderTimelineItems()}
      <IonLoading isOpen={updating} message="Loading..." spinner="crescent" />
    </div>
  );
};

export default Timeline;
