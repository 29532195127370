import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import { RealtimeChannel } from '@supabase/supabase-js';

import { useDentist } from '@/contexts/DentistContext';
import { Patient, getPatient } from '@/data/Patients';
import { Request, getRequestsForDashboard } from '@/data/Requests';
import useToast from '@/hooks/useToast';
import {
  FETCH_REQUESTS_TIMEOUT_IN_MS,
  REQUEST_EXPIRATION_BUFFER_IN_MINUTES,
  REQUEST_STATUS_OPTIONS
} from '@/utils/constants';
import { supabase } from '@/utils/supabase';
import { RequestStatus } from '@/utils/types';
import moment from 'moment';

interface RequestsContextProps {
  loading: boolean;
  loaded: boolean;
  requests: Request[];
  setRequests: React.Dispatch<React.SetStateAction<Request[]>>;
  fetchRequests: ({
    force,
    showLoading
  }: {
    force?: boolean;
    showLoading?: boolean;
  }) => Promise<void>;
  requestsByStatus: Record<RequestStatus, Request[]>;
  filteredRequests: Request[];
  pendingRequestsCount: number;
  selectedStatus: RequestStatus;
  setSelectedStatus: React.Dispatch<React.SetStateAction<RequestStatus>>;
  onUpdateStatus: (request: Request, status: RequestStatus) => void;
  onUpdateAttendanceStatus: (
    request: Request,
    attendanceStatus: string
  ) => void;
  searchQuery: string;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
  searchedRequests: Request[];
}

const RequestsContext = createContext<RequestsContextProps | undefined>(
  undefined
);

interface RequestsProviderProps {
  children: ReactNode;
}

export const RequestsProvider: React.FC<RequestsProviderProps> = ({
  children
}) => {
  const displayToast = useToast();
  const { selectedDentist: dentist } = useDentist();

  const [selectedStatus, setSelectedStatus] = useState<RequestStatus>(
    REQUEST_STATUS_OPTIONS.PENDING
  );
  const [requests, setRequests] = useState<Request[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [channel, setChannel] = useState<RealtimeChannel | null>(null);

  const previousDentistIdRef = useRef<string | null>(null);

  const fetchRequests = useCallback(
    async ({
      force = false,
      showLoading = true
    }: { force?: boolean; showLoading?: boolean } = {}) => {
      if (!dentist?.id) return;

      if (dentist.id === previousDentistIdRef.current && !force) return;

      previousDentistIdRef.current = dentist.id;

      try {
        if (showLoading) setLoading(true);
        const data = await getRequestsForDashboard(dentist.id);
        const validRequests = data.filter(
          (request) => request.patient !== null && request.patient !== undefined
        );
        setRequests(validRequests);

        if (validRequests.length < data.length) {
          const retryData = await getRequestsForDashboard(dentist.id);
          setRequests(
            retryData.filter(
              (request) =>
                request.patient !== null && request.patient !== undefined
            )
          );
        }
        setLoaded(true);
      } catch (error) {
        displayToast({
          message: 'Please check your internet connection and try again.',
          duration: 3000,
          position: 'bottom',
          positionAnchor: 'tabBar'
        });
      } finally {
        if (showLoading) {
          setTimeout(() => {
            setLoading(false);
          }, FETCH_REQUESTS_TIMEOUT_IN_MS);
        }
      }
    },
    [dentist?.id]
  );

  const stream = useCallback(async () => {
    if (!dentist?.id) {
      if (channel) supabase.removeChannel(channel);
      setChannel(null);
      return;
    }

    const channelName = `requests${dentist.id?.replace(
      /-/g,
      ''
    )}${moment().unix()}`;

    const newChannel = supabase
      .channel(channelName)
      .on(
        'postgres_changes',
        {
          event: 'INSERT',
          schema: 'public',
          table: 'requests',
          filter: `dentist_id=eq.${dentist.id}`
        },
        async (payload) => {
          const newRequest = payload.new;
          const patient = await getPatient(newRequest.patient_id);
          setRequests((prevRequests) => [
            ...prevRequests.filter((request) => request.id !== newRequest.id),
            { ...newRequest, patient: patient as Patient } as Request
          ]);
        }
      )
      .subscribe((status) => {
        if (status === 'SUBSCRIBED') {
          fetchRequests({ force: true });
        } else if (status === 'CHANNEL_ERROR' || status === 'TIMED_OUT') {
          fetchRequests({ force: true, showLoading: false });
          stream();
        }
      });

    if (channel) supabase.removeChannel(channel);

    setChannel(newChannel);
  }, [dentist?.id, fetchRequests]);

  useEffect(() => {
    if (!channel) stream();

    return () => {
      if (channel) {
        supabase.removeChannel(channel);
        setChannel(null);
      }
    };
  }, [channel, stream]);

  const onUpdateStatus = useCallback(
    (request: Request, status: RequestStatus) => {
      setRequests((prev) =>
        prev.map((r) => (r.id === request.id ? { ...r, status } : r))
      );
    },
    []
  );

  const onUpdateAttendanceStatus = useCallback(
    (request: Request, attendanceStatus: string) => {
      setRequests((prev) =>
        prev.map((r) =>
          r.id === request.id
            ? { ...r, attendance_status: attendanceStatus }
            : r
        )
      );
    },
    []
  );

  const requestsByStatus = useMemo(() => {
    return requests.reduce(
      (acc, request) => {
        if (!acc[request.status]) {
          acc[request.status] = [];
        }
        acc[request.status].push(request);
        acc[request.status].sort((a, b) => {
          return moment(a.date).diff(moment(b.date));
        });
        return acc;
      },
      {} as Record<RequestStatus, Request[]>
    );
  }, [requests]);

  const filteredRequests = useMemo(() => {
    if (Object.keys(requestsByStatus).length === 0) return [];

    const expirationTime = moment().add(
      REQUEST_EXPIRATION_BUFFER_IN_MINUTES,
      'minutes'
    );

    return (
      requestsByStatus[selectedStatus]?.filter((request: Request) => {
        if (selectedStatus === REQUEST_STATUS_OPTIONS.PENDING) {
          const requestStartTime = moment(
            `${request.date} ${request.start_time}`,
            'YYYY-MM-DD HH:mm'
          );

          if (requestStartTime.isBefore(expirationTime)) {
            return false;
          }

          return true;
        }

        return true;
      }) || []
    );
  }, [requestsByStatus, selectedStatus]);

  const searchedRequests = useMemo(() => {
    if (searchQuery === '') return filteredRequests;

    return filteredRequests.filter((request: Request) => {
      const formattedRequest = {
        ...request,
        date: moment(request.date).format('MMMM D, YYYY'),
        start_time: moment(request.start_time, 'HH:mm').format('h:mm A'),
        end_time: moment(request.end_time, 'HH:mm').format('h:mm A'),
        full_name: `${request.patient?.first_name} ${request.patient?.last_name}`,
        mobile_number: `0${request.patient?.mobile_number}`
      };
      return JSON.stringify(formattedRequest)
        .toLowerCase()
        .replace(/\s+/g, '')
        .includes(searchQuery.toLowerCase().replace(/\s+/g, ''));
    });
  }, [filteredRequests, searchQuery]);

  const pendingRequestsCount = useMemo(() => {
    if (Object.keys(requestsByStatus).length === 0) return 0;

    const currentDateAndTime = moment();
    return (
      requestsByStatus[REQUEST_STATUS_OPTIONS.PENDING]?.filter(
        (request: Request) => {
          const requestDate = moment(request.date);
          const requestStartTime = moment(
            `${request.date} ${request.start_time}`,
            'YYYY-MM-DD HH:mm'
          );

          if (
            requestDate.isBefore(currentDateAndTime, 'day') ||
            (requestDate.isSame(currentDateAndTime, 'day') &&
              currentDateAndTime.add(1.5, 'hours').isAfter(requestStartTime))
          ) {
            return false;
          }

          return true;
        }
      )?.length || 0
    );
  }, [requestsByStatus]);

  useEffect(() => {
    setLoaded(false);
  }, [dentist?.id]);

  useEffect(() => {
    return () => {
      setLoading(false);
      setLoaded(false);
    };
  }, []);

  return (
    <RequestsContext.Provider
      value={{
        loading,
        loaded,
        requests,
        setRequests,
        fetchRequests,
        requestsByStatus,
        filteredRequests,
        pendingRequestsCount,
        selectedStatus,
        setSelectedStatus,
        onUpdateStatus,
        onUpdateAttendanceStatus,
        searchQuery,
        setSearchQuery,
        searchedRequests
      }}
    >
      {children}
    </RequestsContext.Provider>
  );
};

export const useRequests = () => {
  const context = useContext(RequestsContext);
  if (context === undefined) {
    throw new Error('useRequests must be used within a RequestsProvider');
  }
  return context;
};
