import { NavContext } from '@ionic/react';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';

import { User } from '@supabase/supabase-js';

import { useNetwork } from './NetworkContext';
import { useTracker } from '@/contexts/TrackerContext';
import { useVersion } from '@/contexts/VersionContext';
import { User as UserRecord, createUser, getUser } from '@/data/Users';
import useToast from '@/hooks/useToast';
import {
  FETCH_USER_TIMEOUT_IN_MS,
  SIGN_IN_TIMEOUT_IN_MS,
  SIGN_OUT_TIMEOUT_IN_MS
} from '@/utils/constants';
import { ROUTES } from '@/utils/routes';
import { supabase } from '@/utils/supabase';

interface AuthContextType {
  authenticated: boolean;
  user: User | null;
  signIn: (showLoading?: boolean) => Promise<UserRecord | null>;
  signOut: () => void;
  loading: boolean;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const { navigate } = useContext(NavContext);
  const { connected } = useNetwork();
  const { captureEvent, identifyUser, resetTracker } = useTracker();
  const { forceUpdate, maintenanceModeEnabled } = useVersion();

  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const displayToast = useToast();

  const fetchUser = useCallback(async () => {
    if (!connected) return;

    try {
      setLoading(true);
      const fetchUserTimeout = setTimeout(() => {
        setLoading(false);
        displayToast({
          message: 'Please check your internet connection and try again.',
          position: 'bottom'
        });
      }, FETCH_USER_TIMEOUT_IN_MS);

      const {
        data: { user }
      } = await supabase.auth.getUser();

      clearTimeout(fetchUserTimeout);

      if (user) {
        setUser(user);
        setAuthenticated(true);
        setLoading(false);
      } else {
        setLoading(false);
        signOut();
      }
    } catch {
      setLoading(false);
      displayToast({
        message: 'Error logging in. Please contact support.',
        position: 'bottom'
      });
    }
  }, [connected]);

  const signIn = useCallback(async (showLoading: boolean = true) => {
    try {
      if (showLoading) setLoading(true);
      const signInTimeout = setTimeout(() => {
        if (showLoading) setLoading(false);
        displayToast({
          message: 'Please check your internet connection and try again.',
          position: 'bottom'
        });
      }, SIGN_IN_TIMEOUT_IN_MS);

      const {
        data: { user }
      } = await supabase.auth.getUser();

      clearTimeout(signInTimeout);

      if (!user) {
        if (showLoading) setLoading(false);
        displayToast({ message: 'Error logging in. Please contact support.' });
        return null;
      }

      const existingUser = await getUser(user.id);
      if (!existingUser) {
        const newUser = await createUser({
          id: user.id,
          notification_tokens: null
        });
        if (newUser) {
          setUser(user);
          setAuthenticated(true);
          if (showLoading) setLoading(false);

          return newUser;
        } else {
          if (showLoading) setLoading(false);
          displayToast({
            message: 'Error logging in. Please contact support.'
          });

          return null;
        }
      }
      setUser(user);
      setAuthenticated(true);
      identifyUser({ distinctId: user.id });
      captureEvent({ event: 'user signed in' });

      if (showLoading) setLoading(false);

      return existingUser;
    } catch {
      if (showLoading) setLoading(false);
      displayToast({
        message: 'Error logging in. Please contact support.',
        position: 'bottom'
      });

      return null;
    }
  }, []);

  const signOut = useCallback(async () => {
    try {
      setLoading(true);
      const signOutTimeout = setTimeout(() => {
        setLoading(false);
        displayToast({
          message: 'Please check your internet connection and try again.',
          position: 'bottom'
        });
      }, SIGN_OUT_TIMEOUT_IN_MS);

      const { error } = await supabase.auth.signOut();

      clearTimeout(signOutTimeout);

      setUser(null);
      setAuthenticated(false);
      resetTracker();
      setLoading(false);

      if (error) {
        displayToast({
          message: error.message,
          position: 'bottom'
        });
      }
      navigate(ROUTES.WELCOME);
    } catch {
      setLoading(false);
      displayToast({
        message: 'Error logging out. Please contact support.',
        position: 'bottom'
      });
    }
  }, [navigate]);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  useEffect(() => {
    if (!connected || forceUpdate || maintenanceModeEnabled) {
      setLoading(false);
    }
  }, [forceUpdate, maintenanceModeEnabled, connected]);

  useEffect(() => {
    return () => {
      setLoading(false);
    };
  }, []);

  return (
    <AuthContext.Provider
      value={{
        authenticated,
        user,
        signIn,
        signOut,
        loading
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
