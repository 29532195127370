export const formatMobileNumber = (
  mobileNumber: string | null | undefined,
): string => {
  if (!mobileNumber) {
    return "";
  }

  if (mobileNumber.length !== 10) {
    return mobileNumber;
  }

  return `0${mobileNumber.slice(0, 3)} ${mobileNumber.slice(3, 6)} ${
    mobileNumber.slice(6)
  }`;
};
