// useDimensions.ts
import { useEffect, useState } from 'react';

const getDimensions = () => {
  if (!window)
    return {
      width: null,
      height: null
    };

  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
};

const useDimensions = () => {
  const [dimensions, setDimensions] = useState<{
    width: number | null;
    height: number | null;
  }>(getDimensions());

  useEffect(() => {
    const handleResize = () => {
      setDimensions(getDimensions());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return dimensions;
};

export default useDimensions;
