import React, { useCallback, useEffect, useState } from 'react';

import useToast from '@/hooks/useToast';
import moment from 'moment';
import { Carousel } from 'react-responsive-carousel';

import './DatePicker.css';

interface DatePickerProps {
  start?: Date | null;
  end?: Date | null;
  value?: Date | null;
  onSelect: (date: Date) => void;
  onSetMonth: (month: string) => void;
}

const DatePicker: React.FC<DatePickerProps> = ({
  start,
  end,
  value,
  onSelect,
  onSetMonth
}) => {
  const displayToast = useToast();

  const [currentWeekIndex, setCurrentWeekIndex] = useState<number>(0);
  const [weeks, setWeeks] = useState<Date[][]>([]);

  const handleDateClick = (date: Date) => {
    onSelect(date);
  };

  const generateWeeks = useCallback(async () => {
    setWeeks([]);

    const defaultStartDate = moment().subtract(3, 'month').startOf('week');
    const defaultEndDate = moment().add(3, 'month').endOf('week');

    const newWeeks = [];
    let currentDate = start ? moment(start) : defaultStartDate;
    const endDate = end ? moment(end) : defaultEndDate;

    while (currentDate <= endDate) {
      const week = [];
      for (let i = 0; i < 7; i++) {
        if (currentDate <= endDate) {
          week.push(currentDate.toDate());
          currentDate = currentDate.add(1, 'days');
        }
      }
      newWeeks.push(week);
    }

    setWeeks(newWeeks);
  }, [start, end]);

  useEffect(() => {
    generateWeeks();
  }, [generateWeeks]);

  useEffect(() => {
    if (weeks.length > 0) {
      const newValue = value || moment().startOf('day').toDate();
      const valueWeekIndex = weeks.findIndex((week) =>
        week.some((date) => moment(date).isSame(newValue, 'day'))
      );

      if (weeks[valueWeekIndex]?.length > 0) {
        setCurrentWeekIndex(valueWeekIndex);
        onSetMonth(moment(weeks[valueWeekIndex][3]).format('MMMM YYYY'));
      }
    }
  }, [weeks, value]);

  const onSlide = useCallback(
    (index: number) => {
      if (weeks[index]?.length > 0) {
        setCurrentWeekIndex(index);
        onSetMonth(moment(weeks[index][3]).format('MMMM YYYY'));
      }

      if (index === 0 || index === weeks.length - 1) {
        displayToast({
          message:
            "You've reached the end of the calendar. You can only view dates within a 3-month range.",
          duration: 3000,
          position: 'bottom',
          positionAnchor: 'tabBar'
        });
      }
    },
    [weeks]
  );

  return (
    <Carousel
      className="datePicker"
      selectedItem={currentWeekIndex}
      autoPlay={false}
      infiniteLoop={false}
      onChange={onSlide}
      showArrows={false}
      showStatus={false}
      showThumbs={false}
      showIndicators={false}
      useKeyboardArrows={true}
      emulateTouch={true}
    >
      {weeks.map((week, index) => (
        <div className="weekSlide" key={`week-${index}`}>
          {week.map((date) => (
            <div
              key={date.toISOString()}
              className={`dateItem ${moment(date).isSame(value, 'day') ? 'selected' : ''} ${moment(date).isSame(moment(), 'day') ? 'today' : ''}`}
              data-date={date.toISOString()}
              onClick={() => handleDateClick(date)}
            >
              <div className="dayLabel">{moment(date).format('ddd')}</div>
              <div className="dateLabel">{moment(date).format('D')}</div>
            </div>
          ))}
        </div>
      ))}
    </Carousel>
  );
};

export default DatePicker;
