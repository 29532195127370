import { Dentist } from '@/data/Dentists';

export const getInitials = (dentist: Dentist | null): string => {
  if (!dentist) return '';

  const { first_name, last_name } = dentist;
  const firstInitial = first_name ? first_name.charAt(0).toUpperCase() : '';
  const lastInitial = last_name ? last_name.charAt(0).toUpperCase() : '';
  return `${firstInitial}${lastInitial}`;
};
