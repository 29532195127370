import { IonButton, IonIcon, IonInput } from '@ionic/react';
import React, {
  KeyboardEvent,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';

import ButtonContainer from '@/components/common/buttonContainer/ButtonContainer';
import Spacer from '@/components/common/spacer/Spacer';
import { useAddDentist } from '@/contexts/AddDentistContext';
import useToast from '@/hooks/useToast';
import { ADD_DENTIST_STEPS } from '@/utils/constants';
import { arrowForwardCircleOutline } from 'ionicons/icons';

import './Name.css';

interface NameProps {}

const Name: React.FC<NameProps> = () => {
  const { firstName, lastName, setFirstName, setLastName, setCurrentStep } =
    useAddDentist();
  const displayToast = useToast();

  const [hasFirstNameError, setHasFirstNameError] = useState<boolean>(false);
  const [hasLastNameError, setHasLastNameError] = useState<boolean>(false);

  const input = useRef<HTMLIonInputElement>(null);

  const copy = {
    subHeader: 'DENTIST NAME',
    header: (
      <>
        What's your <span>name</span>?
      </>
    ),
    buttonText: 'Next'
  };

  const onFirstNameInput = useCallback(
    (event: CustomEvent) => {
      setFirstName(event.detail.value!);
      setHasFirstNameError(false);
    },
    [setFirstName]
  );

  const onLastNameInput = useCallback(
    (event: CustomEvent) => {
      setLastName(event.detail.value!);
      setHasLastNameError(false);
    },
    [setLastName]
  );

  const onKeyDown = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      onNext();
    }
  }, []);

  const onNext = useCallback(() => {
    let hasError = false;
    if (!firstName || firstName.length <= 1) {
      setHasFirstNameError(true);
      hasError = true;
    }
    if (!lastName || lastName.length <= 1) {
      setHasLastNameError(true);
      hasError = true;
    }
    if (hasError) {
      displayToast({
        message:
          'Please enter a valid first and last name. It should be at least 2 characters.',
        duration: 5000,
        position: 'bottom',
        positionAnchor: 'nextButton'
      });
    } else {
      setHasFirstNameError(false);
      setHasLastNameError(false);
      setCurrentStep(ADD_DENTIST_STEPS.MOBILE_NUMBER);
    }
  }, [firstName, lastName, setCurrentStep]);

  useEffect(() => {
    setTimeout(() => {
      input.current?.setFocus();
    }, 240);
  }, []);

  return (
    <div id="name">
      <Spacer top={32} bottom={24}>
        <h4 id="subHeader">{copy.subHeader}</h4>
        <h1 id="header">{copy.header}</h1>
      </Spacer>

      <Spacer top={16} bottom={102}>
        <div id="form">
          <div className={`input ${hasFirstNameError ? 'error' : ''}`}>
            <IonInput
              ref={input}
              label="First Name"
              labelPlacement="stacked"
              value={firstName}
              placeholder="Enter your first name"
              onIonInput={onFirstNameInput}
              onKeyDown={onKeyDown}
            />
          </div>
          <div className={`input ${hasLastNameError ? 'error' : ''}`}>
            <IonInput
              label="Last Name"
              labelPlacement="stacked"
              value={lastName}
              placeholder="Enter your last name"
              onIonInput={onLastNameInput}
              onKeyDown={onKeyDown}
            />
          </div>
        </div>
      </Spacer>
      <ButtonContainer>
        <IonButton
          id="nextButton"
          onClick={onNext}
          expand="block"
          fill="solid"
          size="default"
          color="primary"
          strong={true}
        >
          <IonIcon slot="start" icon={arrowForwardCircleOutline}></IonIcon>
          {copy.buttonText}
        </IonButton>
      </ButtonContainer>
    </div>
  );
};

export default Name;
