import {
  deleteRecord,
  getRecord,
  getRecords,
  insertRecord,
  updateRecord,
} from "@/utils/helpers/data";

export interface Share {
  code: string;
  created_at: string;
  clinic_id: string;
  dentist_id?: string;
}

/**
 * Creates a new share record.
 * @param share - The share data to insert.
 * @returns The created share or null if insertion failed.
 */
export const createShare = (share: Share) =>
  insertRecord<Share>("shares", share);

/**
 * Retrieves a share by its code.
 * @param code - The code of the share.
 * @returns The share or null if not found.
 */
export const getShareByCode = (code: string) =>
  getRecord<Share>("shares", "code", code);

/**
 * Retrieves all shares associated with a specific clinic ID.
 * @param clinic_id - The ID of the clinic.
 * @returns An array of shares.
 */
export const getSharesByClinicId = (clinic_id: string) =>
  getRecords<Share>("shares", "clinic_id", clinic_id);

/**
 * Retrieves all shares associated with a specific dentist ID.
 * @param dentist_id - The ID of the clinic.
 * @returns An array of shares.
 */
export const getSharesByDentistId = (dentist_id: string) =>
  getRecords<Share>("shares", "dentist_id", dentist_id);

/**
 * Retrieves the clinic ID associated with a specific share code.
 * @param code - The code of the share.
 * @returns The clinic ID or null if not found.
 */
export const getClinicIdByShareCode = async (
  code: string,
): Promise<string | null> => {
  const share = await getRecord<Pick<Share, "clinic_id">>(
    "shares",
    "code",
    code.toLowerCase(),
  );
  return share?.clinic_id ?? null;
};

/**
 * Updates the share code for a specific share.
 * @param id - The ID of the share to update.
 * @param code - The new share code to update.
 * @returns The updated share or null if the update failed.
 */
export const updateShareCode = async (id: string, code: string) => {
  return await updateRecord<Share>("shares", "code", id, {
    code,
  });
};

/**
 * Deletes a share by its ID.
 * @param id - The ID of the share to delete.
 * @returns A boolean indicating whether the deletion was successful.
 * @throws Will throw an error if the deletion fails.
 */
export const deleteShare = async (
  id: string,
): Promise<void> => {
  return deleteRecord("shares", "code", id);
};
