import React from 'react';

import App from './App';
import * as Sentry from '@sentry/capacitor';
import * as SentryReact from '@sentry/react';
import { createRoot } from 'react-dom/client';

Sentry.init(
  {
    dsn: 'https://07644b11feb3df7701d133c7a0cfc4fe@o4508400100048896.ingest.us.sentry.io/4508400101228544',
    release: `odonto-dashboard@10.1`,
    dist: '1',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration()
    ],

    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  },
  SentryReact.init
);

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
