import {
  IonButton,
  IonContent,
  IonIcon,
  IonInput,
  IonLoading,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonText
} from '@ionic/react';
import React, { useCallback, useEffect, useState } from 'react';

import ButtonContainer from '@/components/common/buttonContainer/ButtonContainer';
import Logo from '@/components/common/logo/Logo';
import Padding from '@/components/common/padding/Padding';
import Spacer from '@/components/common/spacer/Spacer';
import { useClinic } from '@/contexts/ClinicContext';
import { useDentist } from '@/contexts/DentistContext';
import { getClinicByMobileNumber } from '@/data/Clinics';
import { updateDentist } from '@/data/Dentists';
import useToast from '@/hooks/useToast';
import { ACCESS_TYPES, MOBILE_NUMBER_LENGTH } from '@/utils/constants';
import { getPossessiveName } from '@/utils/helpers/formatName';
import { Access } from '@/utils/types';
import { arrowBackOutline, checkmarkCircleOutline } from 'ionicons/icons';

import './UpdateAccess.css';

interface UpdateAccessProps {
  isOpen: boolean;
  onClose: () => void;
  presentingElement?: HTMLElement | undefined;
}

const UpdateAccess: React.FC<UpdateAccessProps> = ({
  isOpen,
  onClose,
  presentingElement
}) => {
  const displayToast = useToast();
  const { selectedClinic: clinic } = useClinic();
  const { selectedDentist: dentist, setSelectedDentist } = useDentist();

  const [mobileNumber, setMobileNumber] = useState<string | null>(null);
  const [access, setAccess] = useState<Access>(ACCESS_TYPES.EDIT);
  const [hasError, setHasError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (dentist) {
      setMobileNumber(dentist.mobile_number || null);
      setAccess(dentist.access || ACCESS_TYPES.EDIT);
    }
  }, [dentist?.mobile_number, dentist?.access]);

  const copy = {
    subHeader: 'ACCESS',
    header: (
      <>
        Manage Dr. {getPossessiveName(dentist?.first_name)}{' '}
        <IonText color="primary">access</IonText> to their schedule
      </>
    ),
    message: `Update Dr. ${getPossessiveName(dentist?.first_name)} access level and mobile number—used to log in to their profile.`,
    buttonText: 'Update',
    inputLabel: 'Mobile Number',
    inputPlaceholder: '9---------',
    countryCode: '+63',
    selectLabel: 'Access Level',
    selectPlaceholder: 'Select access level'
  };

  const onNext = useCallback(async () => {
    if (
      mobileNumber &&
      mobileNumber.length === MOBILE_NUMBER_LENGTH &&
      /^\d+$/.test(mobileNumber)
    ) {
      setHasError(false);
      try {
        setLoading(true);
        if (clinic?.mobile_number === mobileNumber) {
          setLoading(false);
          displayToast({
            message:
              'This mobile number is already linked to this clinic account. Please use a different number.',
            duration: 3000,
            position: 'bottom',
            positionAnchor: 'nextButton'
          });
          return;
        }

        const existingClinic = await getClinicByMobileNumber(mobileNumber);
        if (existingClinic && existingClinic.id !== dentist?.clinic_id) {
          setLoading(false);
          displayToast({
            message:
              'This mobile number is already registered. Please use a different number.',
            duration: 3000,
            position: 'bottom',
            positionAnchor: 'nextButton'
          });
          return;
        }
        if (dentist) {
          await updateDentist({
            dentistId: dentist?.id || null,
            dentist: {
              first_name: dentist?.first_name,
              last_name: dentist?.last_name,
              clinic_id: dentist?.clinic_id,
              mobile_number: mobileNumber,
              access: access
            }
          });
          setSelectedDentist({
            ...dentist,
            mobile_number: mobileNumber,
            access: access
          });
          displayToast({
            message: 'Dentist access updated successfully.',
            duration: 3000,
            position: 'bottom',
            positionAnchor: 'tabBar'
          });
        } else {
          displayToast({
            message: 'Something went wrong. Please try again.',
            duration: 3000,
            position: 'bottom',
            positionAnchor: 'tabBar'
          });
        }
        setLoading(false);
        onClose();
      } catch {
        setLoading(false);
        displayToast({
          message: 'Something went wrong. Please try again.',
          duration: 3000,
          position: 'bottom',
          positionAnchor: 'tabBar'
        });
      }
    } else {
      setHasError(true);
      displayToast({
        message: 'Please enter a valid mobile number.',
        duration: 3000,
        position: 'bottom',
        positionAnchor: 'tabBar'
      });
    }
  }, [
    mobileNumber,
    access,
    clinic,
    dentist,
    updateDentist,
    onClose,
    setLoading
  ]);

  useEffect(() => {
    return () => {
      setLoading(false);
    };
  }, []);

  return (
    <IonModal
      isOpen={isOpen}
      onDidDismiss={onClose}
      presentingElement={presentingElement}
    >
      <IonContent>
        <Padding withSafeArea={!presentingElement}>
          <div id="updateAccess">
            <Logo leftIcon={arrowBackOutline} onClickLeftIcon={onClose} />
            <Spacer top={0} bottom={24}>
              <h4 id="subHeader">{copy.subHeader}</h4>
              <h1 id="header">{copy.header}</h1>
              <p id="message">{copy.message}</p>
            </Spacer>

            <Spacer bottom={124}>
              <div id="form">
                <div className="input">
                  <IonSelect
                    label={copy.selectLabel}
                    labelPlacement="stacked"
                    placeholder={copy.selectPlaceholder}
                    value={access}
                    onIonChange={(e: CustomEvent) => setAccess(e.detail.value)}
                  >
                    <IonSelectOption value={ACCESS_TYPES.VIEW}>
                      Can view appointments and requests
                    </IonSelectOption>
                    <IonSelectOption value={ACCESS_TYPES.EDIT}>
                      Can manage appointments and requests
                    </IonSelectOption>
                  </IonSelect>
                </div>
                <div className={`input ${hasError ? 'error' : ''}`}>
                  <IonInput
                    id="mobileNumberInput"
                    label={copy.inputLabel}
                    labelPlacement="stacked"
                    value={mobileNumber}
                    placeholder={copy.inputPlaceholder}
                    onIonInput={(e: CustomEvent) =>
                      setMobileNumber(e.detail.value!)
                    }
                    maxlength={10}
                  >
                    <div slot="start">{copy.countryCode}</div>
                  </IonInput>
                </div>
              </div>
            </Spacer>

            <ButtonContainer>
              <IonButton
                id="nextButton"
                onClick={onNext}
                expand="block"
                fill="solid"
                size="default"
                color="primary"
                strong={true}
                disabled={loading}
              >
                <IonIcon slot="start" icon={checkmarkCircleOutline}></IonIcon>
                {copy.buttonText}
              </IonButton>
            </ButtonContainer>
          </div>
          <IonLoading
            isOpen={loading}
            message="Loading..."
            spinner="crescent"
          />
        </Padding>
      </IonContent>
    </IonModal>
  );
};

export default UpdateAccess;
