import { IonSkeletonText } from '@ionic/react';
import React from 'react';

import Avatar from '@/components/common/avatar/Avatar';

import './DentistTilePlaceholder.css';

const DentistTilePlaceholder: React.FC = () => {
  return (
    <div className="dentistTilePlaceholder">
      <Avatar className="dentistAvatar" loading />
      <p className="dentistName">
        <IonSkeletonText animated className="skeletonName" />
      </p>
    </div>
  );
};

export default DentistTilePlaceholder;
