import { IonSkeletonText } from '@ionic/react';
import React from 'react';

import Spacer from '@/components/common/spacer/Spacer';

import './RequestPlaceholder.css';

const RequestPlaceholder: React.FC = () => {
  return (
    <div className="requestItem">
      <div className="requestDetails">
        <div className="patient">
          <IonSkeletonText animated className="patientNameSkeleton" />
          <IonSkeletonText animated className="patientGenderSkeleton" />
        </div>
        <Spacer top={2} bottom={12}>
          <IonSkeletonText animated className="purposeSkeleton" />
        </Spacer>
        <div className="schedule">
          <div className="date">
            <IonSkeletonText
              animated
              className="dateSkeletonIcon iconSkeleton"
            />
            <IonSkeletonText animated className="dateSkeleton" />
          </div>
          <div className="time">
            <IonSkeletonText
              animated
              className="timeSkeletonIcon iconSkeleton"
            />
            <IonSkeletonText animated className="timeSkeleton" />
          </div>
          <div className="mobileNumber">
            <IonSkeletonText
              animated
              className="mobileNumberSkeletonIcon iconSkeleton"
            />
            <IonSkeletonText animated className="mobileNumberSkeleton" />
          </div>
        </div>
      </div>
      <Spacer bottom={-8}>
        <div className="requestActions">
          <IonSkeletonText animated className="actionButtonSkeleton" />
          <div className="subButtonContainer">
            <IonSkeletonText animated className="actionButtonSkeleton" />
          </div>
        </div>
      </Spacer>
    </div>
  );
};

export default RequestPlaceholder;
