import { IonButton, IonIcon, IonInput } from '@ionic/react';
import React, {
  KeyboardEvent,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';

import ButtonContainer from '@/components/common/buttonContainer/ButtonContainer';
import Spacer from '@/components/common/spacer/Spacer';
import { useGetStarted } from '@/contexts/GetStartedContext';
import useToast from '@/hooks/useToast';
import { GET_STARTED_STEPS } from '@/utils/constants';
import { arrowForwardCircleOutline } from 'ionicons/icons';

import './ClinicName.css';

const ClinicName: React.FC = () => {
  const { clinicName, setClinicName, setCurrentStep } = useGetStarted();
  const displayToast = useToast();
  const [hasError, setHasError] = useState<boolean>(false);
  const input = useRef<HTMLIonInputElement>(null);

  const copy = {
    subHeader: "ENTER YOUR CLINIC'S NAME",
    header: (
      <>
        What's the <span>name</span> of your clinic?
      </>
    ),
    buttonText: 'Next'
  };

  const onInput = useCallback(
    (event: CustomEvent) => {
      setClinicName(event.detail.value!);
      setHasError(false);
    },
    [setClinicName]
  );

  const onKeyDown = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      onNext();
    }
  }, []);

  const onNext = useCallback(() => {
    if (clinicName) {
      setHasError(false);
      setCurrentStep(GET_STARTED_STEPS.MOBILE_NUMBER);
    } else {
      setHasError(true);
      displayToast({
        message: 'Please enter a valid clinic name.',
        duration: 5000,
        position: 'bottom',
        positionAnchor: 'nextButton'
      });
    }
  }, [clinicName, setCurrentStep]);

  useEffect(() => {
    setTimeout(() => {
      input.current?.setFocus();
    }, 240);
  }, []);

  return (
    <div id="clinicName">
      <Spacer top={32} bottom={24}>
        <h4 id="subHeader">{copy.subHeader}</h4>
        <h1 id="header">{copy.header}</h1>
      </Spacer>

      <Spacer top={16} bottom={102}>
        <div id="form">
          <div className={`input ${hasError ? 'error' : ''}`}>
            <IonInput
              ref={input}
              label="Clinic Name"
              labelPlacement="stacked"
              value={clinicName}
              placeholder="Enter the name of your clinic"
              onIonInput={onInput}
              onKeyDown={onKeyDown}
              autoFocus={true}
            />
          </div>
        </div>
      </Spacer>
      <ButtonContainer>
        <IonButton
          id="nextButton"
          onClick={onNext}
          expand="block"
          fill="solid"
          size="default"
          color="primary"
          strong={true}
        >
          <IonIcon slot="start" icon={arrowForwardCircleOutline}></IonIcon>
          {copy.buttonText}
        </IonButton>
      </ButtonContainer>
    </div>
  );
};

export default ClinicName;
