import React from 'react';

import './Padding.css';

interface PaddingProps {
  children: React.ReactNode;
  withSafeArea?: boolean;
}

const Padding: React.FC<PaddingProps> = ({ children, withSafeArea = true }) => {
  return (
    <div
      id="padding"
      className={`ion-padding ${withSafeArea ? 'withSafeArea' : ''}`}
    >
      {children}
    </div>
  );
};

export default Padding;
