import {
  getAllRecords,
  getRecord,
  insertRecord,
  updateRecord,
} from "@/utils/helpers/data";
import { Gender } from "@/utils/types";

export interface Patient {
  id?: string;
  first_name: string | null;
  last_name: string | null;
  mobile_number?: string | null;
  gender?: Gender | null;
  birthday?: string | null;
  created_at?: string;
  updated_at?: string;
  is_user?: boolean;
}

/**
 * Creates a new patient record in the database.
 * @param patient - The patient data to insert.
 * @returns The created patient or null if insertion failed.
 * @throws Will throw an error if the insertion fails.
 */
export const createPatient = async (
  patient: Patient,
): Promise<Patient | null> => {
  return insertRecord<Patient>("patients", patient);
};

/**
 * Updates an existing patient record in the database.
 * @param patientId - The ID of the patient to update.
 * @param patient - The updated patient data.
 * @returns The updated patient or null if update failed.
 * @throws Will throw an error if the update fails.
 */
export const updatePatient = async ({
  patientId,
  patient,
}: {
  patientId: string | null | undefined;
  patient: Patient;
}): Promise<Patient | null> => {
  if (!patientId) return null;
  return updateRecord<Patient>("patients", "id", patientId, patient);
};

/**
 * Retrieves a single patient by their ID.
 * @param patientId - The ID of the patient to retrieve.
 * @returns The requested patient or null if not found.
 * @throws Will throw an error if the retrieval fails.
 */
export const getPatient = async (
  patientId: string | null | undefined,
): Promise<Patient | null> => {
  if (!patientId) return null;
  return getRecord<Patient>("patients", "id", patientId);
};

/**
 * Retrieves all patients from the database.
 * @returns An array of all patients.
 * @throws Will throw an error if the retrieval fails.
 */
export const getAllPatients = async (): Promise<Patient[]> => {
  return getAllRecords<Patient>("patients");
};
