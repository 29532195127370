import { IonIcon } from '@ionic/react';
import React from 'react';

import { fileTrayOutline } from 'ionicons/icons';

import './Empty.css';

interface EmptyProps {
  icon?: string;
  message?: React.ReactNode | string | null;
  marginTop?: number;
}

const Empty: React.FC<EmptyProps> = ({ icon, message, marginTop }) => {
  return (
    <div id="empty" style={{ marginTop }}>
      <IonIcon icon={icon || fileTrayOutline} />
      <p>{message || 'Nothing to see here yet...'}</p>
    </div>
  );
};

export default Empty;
