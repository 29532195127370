import { Clinic } from "@/data/Clinics";
import {
  deleteRecord,
  getRecord,
  getRecords,
  insertRecord,
  updateRecord,
} from "@/utils/helpers/data";
import { supabase } from "@/utils/supabase";
import { Access } from "@/utils/types";

export interface Dentist {
  id?: string;
  first_name: string;
  last_name: string;
  clinic_id: string;
  created_at?: string;
  updated_at?: string;
  mobile_number?: string | null;
  user_id?: string | null;
  clinic?: Clinic;
  access?: Access | null;
  patient_verification_enabled?: boolean;
  specialization?: string | null;
  show_tips?: boolean;
  badge?: number | null;
}

/**
 * Creates a new dentist record in the database.
 * @param dentist - The dentist data to insert.
 * @returns The created dentist or null if insertion failed.
 * @throws Will throw an error if the insertion fails.
 */
export const createDentist = async (
  dentist: Dentist,
): Promise<Dentist | null> => {
  return insertRecord<Dentist>("dentists", dentist);
};

/**
 * Updates an existing dentist record in the database.
 * @param dentistId - The ID of the dentist to update.
 * @param dentist - The updated dentist data.
 * @returns The updated dentist or null if update failed.
 * @throws Will throw an error if the update fails.
 */
export const updateDentist = async ({
  dentistId,
  dentist,
}: {
  dentistId: string | null;
  dentist: Dentist;
}): Promise<Dentist | null> => {
  if (!dentistId) return null;

  const { badge, ...updatedDentist } = dentist;
  return updateRecord<Dentist>("dentists", "id", dentistId, updatedDentist);
};

/**
 * Retrieves a single dentist by their ID.
 * @param dentistId - The ID of the dentist to retrieve.
 * @returns The requested dentist or null if not found.
 * @throws Will throw an error if the retrieval fails.
 */
export const getDentist = async (
  dentistId: string | null,
): Promise<Dentist | null> => {
  if (!dentistId) return null;
  return getRecord<Dentist>("dentists", "id", dentistId);
};

/**
 * Retrieves all dentists associated with a specific clinic ID.
 * @param clinicId - The ID of the clinic.
 * @returns An array of dentists.
 * @throws Will throw an error if the retrieval fails.
 */
export const getDentistsByClinicId = async (
  clinicId: string | null,
): Promise<Dentist[]> => {
  if (!clinicId) return [];
  return getRecords<Dentist>("dentists", "clinic_id", clinicId);
};

/**
 * Deletes a dentist record from the database.
 * @param dentistId - The ID of the dentist to delete.
 * @throws Will throw an error if the deletion fails.
 */
export const deleteDentist = async (
  dentistId: string | null,
): Promise<void> => {
  if (!dentistId) return;

  await deleteRecord("dentists", "id", dentistId);
};

/**
 * Retrieves all dentists associated with a specific user ID.
 * @param userId - The ID of the user.
 * @returns An array of dentists.
 * @throws Will throw an error if the retrieval fails.
 */
export const getDentistsByUserId = async (
  userId: string | null,
): Promise<Dentist[]> => {
  if (!userId) return [];
  const { data, error } = await supabase
    .from("dentists")
    .select("*, clinic:clinic_id (*)")
    .eq("user_id", userId);

  if (error) {
    console.error(`Error fetching dentists by user ID:`, error);
    throw error;
  }

  return data ?? [];
};

/**
 * Retrieves all dentists by their mobile number.
 * @param mobileNumber - The mobile number of the dentists.
 * @returns An array of dentists.
 * @throws Will throw an error if the retrieval fails.
 */
export const getDentistsByMobileNumber = async (
  mobileNumber: string | null,
): Promise<Dentist[]> => {
  if (!mobileNumber) return [];

  return getRecords<Dentist>("dentists", "mobile_number", mobileNumber);
};

/**
 * Updates the show_tips field for a dentist.
 * @param dentistId - The ID of the dentist to update.
 * @param showTips - The value to set for show_tips.
 * @returns The updated dentist or null if update failed.
 * @throws Will throw an error if the update fails.
 */
export const updateDentistShowTips = async (
  dentistId: string,
  showTips: boolean,
): Promise<Dentist | null> => {
  return updateRecord<Dentist>("dentists", "id", dentistId, {
    show_tips: showTips,
  });
};
