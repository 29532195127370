import { IonSkeletonText } from '@ionic/react';
import React from 'react';

import Spacer from '@/components/common/spacer/Spacer';

import './TimelinePlaceholder.css';

interface TimelinePlaceholderProps {
  isFirst?: boolean;
  isLast?: boolean;
}

const TimelinePlaceholder: React.FC<TimelinePlaceholderProps> = ({
  isFirst,
  isLast
}) => {
  return (
    <React.Fragment>
      <div className="timelineItem">
        <div className="timelineTime">
          <div
            className={`timelineCircle ${!isFirst ? 'overlappedStartTime' : ''}`}
          >
            <IonSkeletonText animated className="timeSkeleton" />
          </div>
          <div className="timelineLine"></div>
        </div>
        <div className="timelineContent">
          <p className="timelinePatient">
            <IonSkeletonText animated className="patientSkeleton" />
          </p>
          <Spacer top={2} bottom={8}>
            <p className="timelinePurpose">
              <IonSkeletonText animated className="purposeSkeleton" />
            </p>
          </Spacer>
          <div className="timelineDetails">
            <p className="timelineGender">
              <IonSkeletonText animated className="iconSkeleton" />
              <IonSkeletonText animated className="genderSkeleton" />
            </p>
            <p className="timelineBirthday">
              <IonSkeletonText animated className="iconSkeleton" />
              <IonSkeletonText animated className="birthdaySkeleton" />
            </p>
            <p className="timelineMobileNumber">
              <IonSkeletonText animated className="iconSkeleton" />
              <IonSkeletonText animated className="mobileNumberSkeleton" />
            </p>
          </div>
        </div>
      </div>
      {isLast && (
        <div className="timelineItem">
          <div className="timelineTime">
            <div className="timelineCircle endTime">
              <IonSkeletonText animated className="timeSkeleton" />
            </div>
          </div>
          <div className="timelineContent empty"></div>
        </div>
      )}
    </React.Fragment>
  );
};

export default TimelinePlaceholder;
