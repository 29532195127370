import {
  IonContent,
  IonDatetime,
  IonFab,
  IonFabButton,
  IonIcon,
  IonLoading,
  IonPage,
  IonPopover,
  IonRefresher,
  IonRefresherContent,
  IonRouterLink,
  IonSkeletonText,
  IonText
} from '@ionic/react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Capacitor } from '@capacitor/core';
import { Dialog } from '@capacitor/dialog';

import Empty from '@/components/common/empty/Empty';
import Padding from '@/components/common/padding/Padding';
import Spacer from '@/components/common/spacer/Spacer';
import DatePicker from '@/components/dashboard/calendar/datePicker/DatePicker';
import ManualBooking from '@/components/dashboard/calendar/manualBooking/ManualBooking';
import Timeline from '@/components/dashboard/calendar/timeline/Timeline';
import DashboardHeader from '@/components/dashboard/common/dashboardHeader/DashboardHeader';
import SharePrompt from '@/components/dashboard/common/sharePrompt/SharePrompt';
import { useAuth } from '@/contexts/AuthenticationContext';
import { useClinic } from '@/contexts/ClinicContext';
import { useDentist } from '@/contexts/DentistContext';
import { useNotifications } from '@/contexts/NotificationsContext';
import { useRequests } from '@/contexts/RequestsContext';
import { useShare } from '@/contexts/ShareContext';
import { createRequest } from '@/data/Requests';
import useToast from '@/hooks/useToast';
import {
  ACCESS_TYPES,
  OFF_DUTY_PATIENT_ID,
  REFRESH_TIMEOUT_IN_MS,
  REQUEST_STATUS_OPTIONS
} from '@/utils/constants';
import { registerNotifications } from '@/utils/notifications';
import {
  addOutline,
  banOutline,
  calendarOutline,
  partlySunnyOutline,
  refreshOutline,
  shareOutline,
  swapHorizontalOutline
} from 'ionicons/icons';
import moment from 'moment';

import './Calendar.css';

interface CalendarProps {}

const Calendar: React.FC<CalendarProps> = () => {
  const { user } = useAuth();
  const { isClinic } = useClinic();
  const { selectedDentist: dentist, dentists, onSwitch } = useDentist();
  const { fetchNotifications } = useNotifications();
  const {
    fetchRequests,
    requestsByStatus,
    requests,
    setRequests,
    loading: requestsLoading,
    loaded: requestsLoaded
  } = useRequests();
  const { onShare } = useShare();
  const displayToast = useToast();

  const [month, setMonth] = useState<string>(moment().format('MMMM YYYY'));
  const [selectedDate, setSelectedDate] = useState<Date>(
    moment().startOf('day').toDate()
  );
  const [isNotificationsRegistered, setIsNotificationsRegistered] =
    useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [isManualBookingOpen, setIsManualBookingOpen] =
    useState<boolean>(false);
  const [offDutyLoading, setOffDutyLoading] = useState<boolean>(false);
  const [presentingElement, setPresentingElement] = useState<
    HTMLElement | undefined
  >(undefined);
  const [showDatePicker, setShowDatePicker] = useState<boolean>(true);

  const page = useRef<HTMLElement | undefined>(undefined);

  useEffect(() => {
    setPresentingElement(page.current);
  }, [page.current]);

  const handleRefresh = async (event: CustomEvent) => {
    setTimeout(async () => {
      await fetchRequests({ force: true });
      await fetchNotifications({ force: true });
      event.detail.complete();
    }, REFRESH_TIMEOUT_IN_MS);
  };

  const onSetMonth = (month: string) => {
    setMonth(month);
  };

  const onSelect = (date: Date) => {
    setSelectedDate(date);
    setOpen(false);
  };

  const approvedRequestsForSelectedDate = useMemo(() => {
    const selectedDateString = moment(selectedDate).format('YYYY-MM-DD');
    const approvedRequests =
      requestsByStatus[REQUEST_STATUS_OPTIONS.APPROVED] || [];
    return approvedRequests
      .filter((request) => {
        return moment(request.date).format('YYYY-MM-DD') === selectedDateString;
      })
      .sort((a, b) =>
        moment(a.start_time, 'HH:mm').diff(moment(b.start_time, 'HH:mm'))
      );
  }, [requestsByStatus, selectedDate]);

  const markAsOffDuty = useCallback(async () => {
    const { value: confirmed } = await Dialog.confirm({
      title: 'Confirm',
      message: `Are you sure you want to mark ${moment(selectedDate).format('MMMM D, YYYY')} as off-duty?`,
      okButtonTitle: 'Yes'
    });

    if (!confirmed) return;

    const formattedDate = moment(selectedDate).format('MMMM D, YYYY');

    if (dentist) {
      try {
        setOffDutyLoading(true);
        const newRequest = await createRequest({
          dentist_id: dentist.id!,
          date: moment(selectedDate).format('YYYY-MM-DD'),
          start_time: moment(selectedDate).startOf('day').format('HH:mm'),
          end_time: moment(selectedDate).endOf('day').format('HH:mm'),
          purpose: `All appointment requests for ${moment(selectedDate).format('MMMM D, YYYY')} are blocked. Patients will be prompted to select a different date.`,
          status: REQUEST_STATUS_OPTIONS.APPROVED,
          patient_id: OFF_DUTY_PATIENT_ID
        });
        if (newRequest) {
          setRequests([
            ...requests,
            { ...newRequest, patient: { first_name: 'Off', last_name: 'Duty' } }
          ]);

          displayToast({
            message: `Marked ${formattedDate} as off-duty.`,
            duration: 2400,
            position: 'bottom',
            positionAnchor: 'tabBar'
          });
        } else {
          displayToast({
            message: `Failed to mark ${formattedDate} as off-duty.`,
            duration: 2400,
            position: 'bottom',
            positionAnchor: 'tabBar'
          });
        }
      } catch {
        displayToast({
          message: `Failed to mark ${formattedDate} as off-duty.`,
          duration: 2400,
          position: 'bottom',
          positionAnchor: 'tabBar'
        });
      } finally {
        setOffDutyLoading(false);
      }
    }
  }, [dentist, selectedDate, requests, setRequests]);

  const onToday = () => {
    if (moment().startOf('day').isSame(selectedDate, 'day')) {
      onSelect(moment().subtract(1, 'day').startOf('day').toDate());
      onSelect(moment().startOf('day').toDate());
      displayToast({
        message: "You are already on today's date.",
        duration: 2400,
        position: 'bottom',
        positionAnchor: 'tabBar'
      });
      return;
    }

    onSelect(moment().startOf('day').toDate());
  };

  useEffect(() => {
    if (
      Capacitor.isNativePlatform() &&
      !isNotificationsRegistered &&
      user?.id
    ) {
      registerNotifications(user?.id);
      setIsNotificationsRegistered(true);
    }
  }, [isNotificationsRegistered, Capacitor.isNativePlatform, user?.id]);

  useEffect(() => {
    return () => {
      setOffDutyLoading(false);
    };
  }, []);

  const hasAccess = useMemo(() => {
    return isClinic || dentist?.access === ACCESS_TYPES.EDIT;
  }, [isClinic, dentist?.access]);

  const isEmpty = useMemo(() => {
    return (
      !requestsLoading &&
      requestsLoaded &&
      approvedRequestsForSelectedDate.length === 0
    );
  }, [requestsLoading, requestsLoaded, approvedRequestsForSelectedDate]);

  return (
    <IonPage id="calendar" ref={page}>
      <IonContent>
        <IonRefresher
          id="calendarRefresher"
          slot="fixed"
          onIonRefresh={handleRefresh}
        >
          <IonRefresherContent
            pullingIcon={refreshOutline}
            refreshingSpinner="crescent"
          ></IonRefresherContent>
        </IonRefresher>

        <Padding>
          <div id="fixedHeader">
            <DashboardHeader
              title={
                <>
                  {month.split(' ')[0]}{' '}
                  <IonText color="primary">{month.split(' ')[1]}</IonText>
                </>
              }
              presentingElement={presentingElement}
            />
            <div id="calendarDatePicker">
              {showDatePicker ? (
                <DatePicker
                  onSelect={onSelect}
                  onSetMonth={onSetMonth}
                  value={selectedDate}
                />
              ) : (
                <div id="datePickerPlaceholder">
                  {Array.from({ length: 7 }).map((_, index) => (
                    <div
                      className="datePickerSkeletonContainer"
                      key={`datePickerSkeleton-${index}`}
                    >
                      <IonSkeletonText
                        animated
                        className="datePickerSkeleton"
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
            <Spacer top={8} bottom={8}>
              <div id="calendarDatePickerButtons">
                <div onClick={() => onToday()}>
                  <IonIcon icon={partlySunnyOutline} />
                  <p>Today</p>
                </div>
                <div onClick={() => setOpen(true)}>
                  <IonIcon icon={calendarOutline} />
                  <p>Select Date</p>
                </div>
                {dentists.length > 1 ? (
                  <div onClick={() => onSwitch()}>
                    <IonIcon icon={swapHorizontalOutline} />
                    <p>Switch {isClinic ? 'Dentist' : 'Clinic'}</p>
                  </div>
                ) : (
                  <div onClick={() => onShare()}>
                    <IonIcon icon={shareOutline} />
                    <p>Share Booking Link</p>
                  </div>
                )}
              </div>
            </Spacer>
          </div>
          <Spacer top={isEmpty ? 271 : 204} bottom={isEmpty ? 72 : 24}>
            {isEmpty ? (
              <>
                <Empty
                  icon={calendarOutline}
                  message={`No appointments for ${moment(selectedDate).format('MMMM D, YYYY')}`}
                  marginTop={0}
                />
                <Spacer top={32} />
                <SharePrompt />
                {hasAccess &&
                  moment(selectedDate).isSameOrAfter(
                    moment().startOf('day')
                  ) && (
                    <div id="offDuty">
                      <IonRouterLink onClick={markAsOffDuty}>
                        <IonIcon icon={banOutline} />
                        Mark as Off-Duty
                      </IonRouterLink>
                    </div>
                  )}
              </>
            ) : (
              <Timeline
                requests={approvedRequestsForSelectedDate}
                loading={requestsLoading || !requestsLoaded}
              />
            )}
          </Spacer>
        </Padding>

        <IonPopover
          id="calendarPopover"
          isOpen={open}
          onDidDismiss={() => setOpen(false)}
        >
          <IonDatetime
            min={moment()
              .subtract(3, 'months')
              .startOf('week')
              .format('YYYY-MM-DD')}
            max={moment().add(3, 'months').endOf('week').format('YYYY-MM-DD')}
            presentation="date"
            onIonChange={(e: CustomEvent) => onSelect(new Date(e.detail.value))}
          />
        </IonPopover>

        {hasAccess && (
          <>
            <IonFab vertical="bottom" horizontal="end" slot="fixed">
              <IonFabButton onClick={() => setIsManualBookingOpen(true)}>
                <IonIcon icon={addOutline} />
              </IonFabButton>
            </IonFab>

            <ManualBooking
              isOpen={isManualBookingOpen}
              onClose={() => setIsManualBookingOpen(false)}
              selectedDate={selectedDate}
              presentingElement={presentingElement}
            />
          </>
        )}

        <IonLoading
          isOpen={offDutyLoading}
          message="Loading..."
          spinner="crescent"
        />
      </IonContent>
    </IonPage>
  );
};

export default Calendar;
