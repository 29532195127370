import { isPlatform } from '@ionic/react';
import React from 'react';

import useDimensions from '@/hooks/useDimensions';
import useDetectKeyboardOpen from 'use-detect-keyboard-open';

import './ButtonContainer.css';

interface ButtonContainerProps {
  position?: 'static' | 'relative' | 'absolute' | 'fixed' | 'sticky';
  hideOnKeyboardOpen?: boolean;
  children: React.ReactNode;
}

const ButtonContainer: React.FC<ButtonContainerProps> = ({
  position = 'fixed',
  hideOnKeyboardOpen = true,
  children
}) => {
  const isKeyboardOpen = useDetectKeyboardOpen();
  const { height } = useDimensions();

  if (hideOnKeyboardOpen && isKeyboardOpen && height && height <= 800) {
    return null;
  }

  return (
    <div id="buttonContainer" className="ion-padding" style={{ position }}>
      {children}
    </div>
  );
};

export default ButtonContainer;
