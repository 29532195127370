export const ROUTES = {
  ROOT: "/",
  WELCOME: "/",
  GET_STARTED: "/get-started",
  SIGN_IN: "/sign-in",
  SELECT_DENTIST: "/",
  ADD_DENTIST: "/dentists/add",
  CALENDAR: "/",
  REQUESTS: "/requests",
  PROFILE: "/profile",
  NO_INTERNET: "/no-internet",
  UPDATE: "/update",
  MAINTENANCE: "/maintenance",
};
