import { IonButton, IonIcon, IonText } from '@ionic/react';
import React, { useCallback, useMemo } from 'react';

import { Dialog } from '@capacitor/dialog';

import Spacer from '@/components/common/spacer/Spacer';
import { useClinic } from '@/contexts/ClinicContext';
import { useDentist } from '@/contexts/DentistContext';
import {
  Request,
  approveRequest,
  cancelRequest,
  declineRequest,
  rescheduleRequest
} from '@/data/Requests';
import useToast from '@/hooks/useToast';
import {
  ACCESS_TYPES,
  GENDER_OPTIONS,
  REQUEST_ACTIONS,
  REQUEST_ACTION_ICONS,
  REQUEST_STATUS_OPTIONS
} from '@/utils/constants';
import capitalize from '@/utils/helpers/capitalize';
import { formatMobileNumber } from '@/utils/helpers/formatMobileNumber';
import { formatName } from '@/utils/helpers/formatName';
import { Gender, RequestAction, RequestStatus } from '@/utils/types';
import {
  calendarClearOutline,
  callOutline,
  femaleOutline,
  maleFemaleOutline,
  maleOutline,
  timeOutline
} from 'ionicons/icons';
import moment from 'moment';

import './RequestItem.css';

interface RequestProps {
  request: Request;
  onUpdateStatus: (request: Request, status: RequestStatus) => void;
  setUpdating: React.Dispatch<React.SetStateAction<boolean>>;
}

const RequestItem: React.FC<RequestProps> = ({
  request,
  onUpdateStatus,
  setUpdating
}) => {
  const { patient, status, date, start_time, end_time, purpose } = request;
  const { selectedDentist: dentist } = useDentist();
  const { isClinic } = useClinic();

  const displayToast = useToast();

  const handleAction = useCallback(
    async (action: RequestAction) => {
      const { value: confirmed } = await Dialog.confirm({
        title: 'Confirm',
        message: `Are you sure you want to ${action.toUpperCase()} the request from ${formatName(patient?.first_name)}?`,
        okButtonTitle: 'Yes'
      });

      if (!confirmed) return;

      setUpdating(true);
      const actionMap = {
        [REQUEST_ACTIONS.APPROVE]: {
          requestFunc: approveRequest,
          status: REQUEST_STATUS_OPTIONS.APPROVED,
          message:
            'Request approved. Notifications and reminders have been sent to your patient.'
        },
        [REQUEST_ACTIONS.DECLINE]: {
          requestFunc: declineRequest,
          status: REQUEST_STATUS_OPTIONS.DECLINED,
          message:
            'Request declined. A new booking link has been sent to your patient for rescheduling.'
        },
        [REQUEST_ACTIONS.RESCHEDULE]: {
          requestFunc: rescheduleRequest,
          status: REQUEST_STATUS_OPTIONS.RESCHEDULED,
          message:
            'Request rescheduled. A new booking link has been sent to your patient for rescheduling.'
        },
        [REQUEST_ACTIONS.CANCEL]: {
          requestFunc: cancelRequest,
          status: REQUEST_STATUS_OPTIONS.CANCELLED,
          message:
            'Request cancelled. A new booking link has been sent to your patient for rescheduling.'
        }
      };

      const { requestFunc, status, message } = actionMap[action];
      if (
        action === REQUEST_ACTIONS.CANCEL ||
        action === REQUEST_ACTIONS.RESCHEDULE ||
        action === REQUEST_ACTIONS.DECLINE
      ) {
        const { value: reason, cancelled } = await Dialog.prompt({
          title: `Reason for ${action}`,
          message: 'Please provide a reason or any additional notes',
          inputPlaceholder: 'Enter reason or notes (optional)'
        });
        if (cancelled) return;
        await requestFunc(request.id!, reason === '' ? null : reason);
      } else {
        await requestFunc(request.id!);
      }
      onUpdateStatus(request, status);
      displayToast({
        message,
        duration: 5000,
        position: 'bottom',
        positionAnchor: 'tabBar'
      });
      setUpdating(false);
    },
    [request.id, onUpdateStatus, patient?.first_name]
  );

  const renderActionButton = (
    action: RequestAction,
    color: string,
    fill: 'clear' | 'solid' = 'solid'
  ) => (
    <IonButton
      expand="block"
      onClick={() => handleAction(action)}
      color={color}
      fill={fill}
      strong={true}
    >
      <IonIcon slot="start" icon={REQUEST_ACTION_ICONS[action]} />
      {capitalize(action)}
    </IonButton>
  );

  const renderGenderIcon = (gender: Gender | null | undefined) => {
    switch (gender) {
      case GENDER_OPTIONS.FEMALE:
        return femaleOutline;
      case GENDER_OPTIONS.OTHERS:
        return maleFemaleOutline;
      default:
        return maleOutline;
    }
  };

  const hasAccess = useMemo(() => {
    return isClinic || dentist?.access === ACCESS_TYPES.EDIT;
  }, [isClinic, dentist?.access]);

  return (
    <div className="requestItem">
      <div className="requestDetails">
        <div className="patient">
          <p className="patientName">
            {formatName(patient?.first_name)}{' '}
            <IonText color="primary">{formatName(patient?.last_name)}</IonText>
          </p>
          {patient?.gender && (
            <IonIcon
              icon={renderGenderIcon(patient.gender)}
              className="gender"
            />
          )}
        </div>
        <Spacer top={2} bottom={12}>
          <p className="purpose">{purpose}</p>
        </Spacer>
        <div className="schedule">
          <div className="date">
            <IonIcon icon={calendarClearOutline} />
            <p>{moment(date).format('MMMM D, YYYY')}</p>
          </div>
          <div className="time">
            <IonIcon icon={timeOutline} />
            <p>
              {moment(start_time, 'HH:mm').format('h:mm A')} -{' '}
              {moment(end_time, 'HH:mm').format('h:mm A')}
            </p>
          </div>
          {patient?.mobile_number && (
            <div className="mobileNumber">
              <IonIcon icon={callOutline} />
              <p>{formatMobileNumber(patient.mobile_number)}</p>
            </div>
          )}
        </div>
      </div>
      {hasAccess && status === REQUEST_STATUS_OPTIONS.PENDING && (
        <Spacer bottom={-8}>
          <div className="requestActions">
            {renderActionButton(REQUEST_ACTIONS.APPROVE, 'success')}
            {renderActionButton(REQUEST_ACTIONS.DECLINE, 'danger', 'clear')}
          </div>
        </Spacer>
      )}
      {hasAccess &&
        status === REQUEST_STATUS_OPTIONS.APPROVED &&
        moment().isBefore(moment(date + ' ' + start_time), 'minute') && (
          <Spacer bottom={-8}>
            <div className="requestActions">
              {renderActionButton(REQUEST_ACTIONS.RESCHEDULE, 'dark')}
              {renderActionButton(REQUEST_ACTIONS.CANCEL, 'danger', 'clear')}
            </div>
          </Spacer>
        )}
    </div>
  );
};

export default RequestItem;
