import {
  IonBadge,
  IonIcon,
  IonLabel,
  IonPopover,
  IonRouterLink
} from '@ionic/react';
import { NavContext } from '@ionic/react';
import React, { useCallback, useContext, useMemo, useState } from 'react';

import Avatar from '@/components/common/avatar/Avatar';
import Notifications from '@/components/dashboard/notifications/Notifications';
import { useAuth } from '@/contexts/AuthenticationContext';
import { useClinic } from '@/contexts/ClinicContext';
import { useDentist } from '@/contexts/DentistContext';
import { useNotifications } from '@/contexts/NotificationsContext';
import { generateRandomString } from '@/utils/helpers/generateRandomString';
import { getInitials } from '@/utils/helpers/getInitials';
import { ROUTES } from '@/utils/routes';
import {
  logOutOutline,
  notificationsOutline,
  swapHorizontalOutline
} from 'ionicons/icons';

import './HeaderIcons.css';

interface HeaderIconsProps {
  presentingElement?: HTMLElement | undefined;
  onCloseNotifications?: () => void;
}

const HeaderIcons: React.FC<HeaderIconsProps> = ({
  presentingElement,
  onCloseNotifications
}) => {
  const { selectDentist, selectedDentist: dentist } = useDentist();
  const { isClinic } = useClinic();
  const { signOut } = useAuth();
  const { navigate } = useContext(NavContext);
  const { readNotification, unreadCount } = useNotifications();

  const [isNotificationsOpen, setIsNotificationsOpen] =
    useState<boolean>(false);

  const handleSwitchDentist = useCallback(async () => {
    await selectDentist(null);
    navigate(ROUTES.SELECT_DENTIST);
  }, [selectDentist, navigate]);

  const handleSignOut = useCallback(async () => {
    await selectDentist(null);
    signOut();
  }, [selectDentist, signOut]);

  const triggerId = useMemo(() => generateRandomString(), []);
  const popoverId = useMemo(() => generateRandomString(), []);

  return (
    <div className="headerIcons">
      <div
        className="notificationIcon"
        onClick={() => setIsNotificationsOpen(true)}
      >
        <IonIcon icon={notificationsOutline} />
        {unreadCount > 0 && <IonBadge color="danger">{unreadCount}</IonBadge>}
      </div>
      <div className={`avatarIcon ${triggerId}`} id={triggerId}>
        <Avatar initials={getInitials(dentist)} />
      </div>
      <IonPopover
        id={popoverId}
        className="avatarPopover"
        trigger={triggerId}
        triggerAction="click"
        reference="trigger"
        side="bottom"
        alignment="end"
        arrow={false}
      >
        <div className="avatarPopoverContent">
          <IonRouterLink
            className="avatarPopoverItem"
            onClick={handleSwitchDentist}
          >
            <div className="avatarPopoverItemContent">
              <IonIcon icon={swapHorizontalOutline} />
              <IonLabel>Switch {isClinic ? 'Dentist' : 'Clinic'}</IonLabel>
            </div>
          </IonRouterLink>
          <IonRouterLink className="avatarPopoverItem" onClick={handleSignOut}>
            <div className="avatarPopoverItemContent">
              <IonIcon icon={logOutOutline} />
              <IonLabel>Sign Out</IonLabel>
            </div>
          </IonRouterLink>
        </div>
      </IonPopover>
      <Notifications
        isOpen={isNotificationsOpen}
        onClose={() => {
          setIsNotificationsOpen(false);
          readNotification();
          onCloseNotifications?.();
        }}
        presentingElement={presentingElement}
      />
    </div>
  );
};

export default HeaderIcons;
