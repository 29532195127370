import {
  deleteRecord,
  getAllRecords,
  getRecord,
  getRecords,
  insertRecord,
  updateRecord,
} from "@/utils/helpers/data";
import { supabase } from "@/utils/supabase";

export interface Service {
  id?: string;
  name: string;
  duration: number;
  dentist_id?: string | null;
}

/**
 * Creates a new service record in the database.
 * @param service - The service data to insert.
 * @returns The created service or null if insertion failed.
 * @throws Will throw an error if the insertion fails.
 */
export const createService = async (
  service: Service,
): Promise<Service | null> => {
  return insertRecord<Service>("services", service);
};

/**
 * Updates an existing service record in the database.
 * @param serviceId - The ID of the service to update.
 * @param service - The updated service data.
 * @returns The updated service or null if update failed.
 * @throws Will throw an error if the update fails.
 */
export const updateService = async (
  serviceId: string | null | undefined,
  service: Service,
): Promise<Service | null> => {
  if (!serviceId) return null;
  return updateRecord<Service>("services", "id", serviceId, service);
};

/**
 * Retrieves a single service by its ID.
 * @param serviceId - The ID of the service to retrieve.
 * @returns The requested service or null if not found.
 * @throws Will throw an error if the retrieval fails.
 */
export const getService = async (
  serviceId: string | null | undefined,
): Promise<Service | null> => {
  if (!serviceId) return null;
  return getRecord<Service>("services", "id", serviceId);
};

/**
 * Retrieves all services from the database.
 * @returns An array of all services.
 * @throws Will throw an error if the retrieval fails.
 */
export const getAllServices = async (): Promise<Service[]> => {
  return getAllRecords<Service>("services");
};

/**
 * Retrieves all services for a specific dentist by the dentist's ID.
 * @param dentistId - The ID of the dentist whose services to retrieve.
 * @returns An array of services for the specified dentist.
 * @throws Will throw an error if the retrieval fails.
 */
export const getServicesByDentistId = async (
  dentistId: string,
): Promise<Service[]> => {
  return getRecords<Service>("services", "dentist_id", dentistId);
};

/**
 * Retrieves all default services (services with no specific dentist) from the database.
 * @returns An array of default services.
 * @throws Will throw an error if the retrieval fails.
 */
export const getDefaultServices = async (): Promise<Service[]> => {
  const { data, error } = await supabase
    .from("services")
    .select("*")
    .is("dentist_id", null);

  if (error) {
    console.error("Error fetching default services:", error);
    throw error;
  }

  return data ?? [];
};

/**
 * Retrieves all service options from the service_options table.
 * @returns An array of service_option names.
 * @throws Will throw an error if the retrieval fails.
 */
export const getServiceOptions = async (): Promise<string[]> => {
  const serviceOptions = await getAllRecords<{ name: string }>(
    "service_options",
  );
  return serviceOptions.map((option) => option.name);
};

/**
 * Deletes a service by its ID.
 * @param serviceId - The ID of the service to delete.
 * @returns A boolean indicating whether the deletion was successful.
 * @throws Will throw an error if the deletion fails.
 */
export const deleteService = async (
  serviceId: string,
): Promise<void> => {
  return deleteRecord("services", "id", serviceId);
};
