import { getAllRecords, getRecord } from "@/utils/helpers/data";

export interface SupportedVersion {
  version: string;
  created_at: string;
  maintenance_mode_enabled: boolean;
}

/**
 * Retrieves a single supported version by its version number.
 * @param version - The version number to retrieve.
 * @returns The requested version or null if not found.
 * @throws Will throw an error if the retrieval fails.
 */
export const getSupportedVersion = async (
  version: string,
): Promise<SupportedVersion | null> => {
  return getRecord<SupportedVersion>("supported_versions", "version", version);
};

/**
 * Retrieves all supported versions from the database.
 * @returns An array of all supported versions.
 * @throws Will throw an error if the retrieval fails.
 */
export const getAllSupportedVersions = async (): Promise<
  SupportedVersion[]
> => {
  return getAllRecords<SupportedVersion>("supported_versions");
};
