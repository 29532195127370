import { NavContext } from '@ionic/react';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';

import { TABS } from '@/utils/tabs';

interface TabsContextType {
  selectedTab: string;
  setSelectedTab: React.Dispatch<React.SetStateAction<string>>;
  handleTabButtonClick: (tab: string) => void;
  handleTabChange: (event: CustomEvent) => void;
  handleSignOut: () => void;
}

const TabsContext = createContext<TabsContextType | undefined>(undefined);

interface TabsProviderProps {
  children: React.ReactNode;
}

export const TabsProvider: React.FC<TabsProviderProps> = ({ children }) => {
  const { routeInfo } = useContext(NavContext);

  const defaultTab = TABS.CALENDAR;
  const [selectedTab, setSelectedTab] = useState<string>(defaultTab);

  const handleTabButtonClick = useCallback((tab: string) => {
    setSelectedTab(tab);
  }, []);

  const handleTabChange = useCallback((event: CustomEvent) => {
    setSelectedTab(event.detail.tab);
  }, []);

  const handleSignOut = useCallback(() => {
    setSelectedTab(defaultTab);
  }, [defaultTab]);

  useEffect(() => {
    if (routeInfo?.pathname) {
      const path = routeInfo.pathname;
      const tab = path.split('/')[1];
      setSelectedTab(tab || defaultTab);
    }
  }, [routeInfo?.pathname]);

  return (
    <TabsContext.Provider
      value={{
        selectedTab,
        setSelectedTab,
        handleTabButtonClick,
        handleTabChange,
        handleSignOut
      }}
    >
      {children}
    </TabsContext.Provider>
  );
};

export const useTabs = () => {
  const context = useContext(TabsContext);
  if (context === undefined) {
    throw new Error('useTabs must be used within a TabsProvider');
  }
  return context;
};
