import { IonBadge } from '@ionic/react';
import React from 'react';

import Avatar from '@/components/common/avatar/Avatar';
import { Dentist } from '@/data/Dentists';
import { formatName } from '@/utils/helpers/formatName';
import { getInitials } from '@/utils/helpers/getInitials';

import './DentistTile.css';

interface DentistTileProps {
  dentist: Dentist;
  onSelectDentist: (dentist: Dentist) => void;
}

const DentistTile: React.FC<DentistTileProps> = ({
  dentist,
  onSelectDentist
}) => {
  const formattedDentistName = `Dr. ${formatName(dentist.first_name)}`;
  const badge = dentist.badge || 0;

  return (
    <div className="dentistTile" onClick={() => onSelectDentist(dentist)}>
      {badge > 0 && (
        <IonBadge className="dentistBadge" color="danger">
          {badge}
        </IonBadge>
      )}
      <Avatar className="dentistAvatar" initials={getInitials(dentist)} />
      <p className="dentistName">{formattedDentistName}</p>
      {dentist.clinic?.name && (
        <p className="clinicName">{dentist.clinic?.name}</p>
      )}
    </div>
  );
};

export default DentistTile;
