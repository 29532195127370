import {
  IonBadge,
  IonContent,
  IonIcon,
  IonModal,
  NavContext
} from '@ionic/react';
import React, { useContext, useRef } from 'react';

import Empty from '@/components/common/empty/Empty';
import ModalHeader from '@/components/common/modalHeader/ModalHeader';
import Padding from '@/components/common/padding/Padding';
import Spacer from '@/components/common/spacer/Spacer';
import { useNotifications } from '@/contexts/NotificationsContext';
import { useRequests } from '@/contexts/RequestsContext';
import { useTabs } from '@/contexts/TabsContext';
import { DentistNotification } from '@/data/DentistNotifications';
import {
  NOTIFICATION_CATEGORIES,
  NOTIFICATION_CATEGORY_IDENTIFIERS,
  NOTIFICATION_TITLES
} from '@/utils/constants';
import { formatNotificationTimestamp } from '@/utils/helpers/formatNotificationTimestamp';
import { ROUTES } from '@/utils/routes';
import { chevronForwardOutline, notificationsOutline } from 'ionicons/icons';
import moment from 'moment';

import './Notifications.css';

interface NotificationsProps {
  isOpen: boolean;
  onClose: () => void;
  presentingElement?: HTMLElement | undefined;
}

const Notifications: React.FC<NotificationsProps> = ({
  isOpen,
  onClose,
  presentingElement
}) => {
  const { navigate } = useContext(NavContext);
  const { notifications, groupedNotifications } = useNotifications();
  const { setSelectedTab } = useTabs();
  const { requests } = useRequests();

  const modal = useRef<HTMLIonModalElement>(null);

  const handleNotificationClick = (notification: DentistNotification) => {
    if (notification.request_id) {
      const request = requests.find(
        (request) => request.id === notification.request_id
      );
      if (request) {
        navigate(
          `${ROUTES.REQUESTS}?query=${encodeURIComponent(
            request?.patient?.first_name || ''
          )} ${encodeURIComponent(request?.patient?.last_name || '')}&status=${
            request.status
          }`
        );
        setSelectedTab('requests');
        onClose();
      }
    }
  };

  const getNotificationCategory = (message: string) => {
    if (message.includes(NOTIFICATION_CATEGORY_IDENTIFIERS.REMINDER)) {
      return NOTIFICATION_CATEGORIES.REMINDER;
    } else if (message.includes(NOTIFICATION_CATEGORY_IDENTIFIERS.CANCELLED)) {
      return NOTIFICATION_CATEGORIES.CANCELLED;
    } else if (
      message.includes(NOTIFICATION_CATEGORY_IDENTIFIERS.RESCHEDULED)
    ) {
      return NOTIFICATION_CATEGORIES.RESCHEDULED;
    }
    return NOTIFICATION_CATEGORIES.REQUEST;
  };

  const getNotificationTitle = (category: string) => {
    switch (category) {
      case NOTIFICATION_CATEGORIES.REMINDER:
        return NOTIFICATION_TITLES.REMINDER;
      case NOTIFICATION_CATEGORIES.CANCELLED:
        return NOTIFICATION_TITLES.CANCELLED;
      case NOTIFICATION_CATEGORIES.RESCHEDULED:
        return NOTIFICATION_TITLES.RESCHEDULED;
      default:
        return NOTIFICATION_TITLES.REQUEST;
    }
  };

  const getNotificationAction = (category: string) => {
    return category === NOTIFICATION_CATEGORIES.REQUEST
      ? 'View Request'
      : 'View Appointment';
  };

  return (
    <IonModal
      isOpen={isOpen}
      onDidDismiss={onClose}
      presentingElement={presentingElement}
      id="notifications"
      ref={modal}
    >
      <IonContent>
        <Padding withSafeArea={!presentingElement}>
          <ModalHeader
            title="Notifications"
            onClose={() => {
              modal.current?.dismiss();
            }}
          />
          {notifications.length === 0 && <Empty />}
          <Spacer top={24} />
          <div id="notificationList">
            {groupedNotifications.unread.length > 0 && (
              <div className="notificationListSection">
                <h3 className="notificationListTitle">
                  Unread{' '}
                  <IonBadge color="danger">
                    {groupedNotifications.unread.length}
                  </IonBadge>
                </h3>
                <div className="notificationListItems">
                  {groupedNotifications.unread.map((notification) => {
                    const notificationCategory = getNotificationCategory(
                      notification.message
                    );
                    const notificationTitle =
                      getNotificationTitle(notificationCategory);
                    const notificationAction =
                      getNotificationAction(notificationCategory);

                    return (
                      <div
                        key={notification.id}
                        className="notificationListItem"
                        onClick={() => handleNotificationClick(notification)}
                      >
                        <div className="notificationInformation">
                          <p
                            className={`notificationCategory ${notificationCategory}`}
                          >
                            {notificationCategory.toUpperCase()}
                          </p>
                          <p className="notificationTimestamp">
                            {formatNotificationTimestamp(
                              notification.created_at
                            )}
                          </p>
                        </div>
                        <div className="notificationContent">
                          <div className="notificationTitleContainer">
                            <div className="notificationUnreadBadge"></div>
                            <p className="notificationTitle">
                              {notificationTitle}
                            </p>
                          </div>
                          <p className="notificationMessage">
                            {notification.message.replace(
                              NOTIFICATION_CATEGORY_IDENTIFIERS.REMINDER,
                              ''
                            )}
                          </p>
                        </div>
                        <div className="notificationAction">
                          <p className="notificationActionText">
                            {notificationAction}
                          </p>
                          <IonIcon
                            icon={chevronForwardOutline}
                            className="notificationActionIcon"
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {Object.keys(groupedNotifications.readByDate).map((date) => (
              <div key={date} className="notificationListSection">
                <h3 className="notificationListTitle">
                  {moment(date, 'YYYY-MM-DD').isSame(moment(), 'day')
                    ? 'Today'
                    : moment(date, 'YYYY-MM-DD').format('MMMM D, YYYY')}
                </h3>
                <div className="notificationListItems">
                  {groupedNotifications.readByDate[date].map((notification) => {
                    const notificationCategory = getNotificationCategory(
                      notification.message
                    );
                    const notificationTitle =
                      getNotificationTitle(notificationCategory);
                    const notificationAction =
                      getNotificationAction(notificationCategory);

                    return (
                      <div
                        key={notification.id}
                        className="notificationListItem"
                        onClick={() => handleNotificationClick(notification)}
                      >
                        <div className="notificationInformation">
                          <p
                            className={`notificationCategory ${notificationCategory}`}
                          >
                            {notificationCategory.toUpperCase()}
                          </p>
                          <p className="notificationTimestamp">
                            {formatNotificationTimestamp(
                              notification.created_at
                            )}
                          </p>
                        </div>
                        <div className="notificationContent">
                          <div className="notificationTitleContainer">
                            <p className="notificationTitle">
                              {notificationTitle}
                            </p>
                          </div>
                          <p className="notificationMessage">
                            {notification.message.replace(
                              NOTIFICATION_CATEGORY_IDENTIFIERS.REMINDER,
                              ''
                            )}
                          </p>
                        </div>
                        <div className="notificationAction">
                          <p className="notificationActionText">
                            {notificationAction}
                          </p>
                          <IonIcon
                            icon={chevronForwardOutline}
                            className="notificationActionIcon"
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            ))}
          </div>
        </Padding>
      </IonContent>
    </IonModal>
  );
};

export default Notifications;
