import React from 'react';

import HeaderIcons from '@/components/common/headerIcons/HeaderIcons';

import './DashboardHeader.css';

interface DashboardHeaderProps {
  title: string | React.ReactNode;
  showIcons?: boolean;
  presentingElement?: HTMLElement | undefined;
  onCloseNotifications?: () => void;
}

const DashboardHeader: React.FC<DashboardHeaderProps> = ({
  title,
  showIcons = true,
  presentingElement,
  onCloseNotifications
}) => {
  return (
    <div id="dashboardHeader">
      <h1 id="dashboardHeaderTitle">{title}</h1>
      {showIcons && (
        <HeaderIcons
          presentingElement={presentingElement}
          onCloseNotifications={onCloseNotifications}
        />
      )}
    </div>
  );
};

export default DashboardHeader;
