import {
  IonButton,
  IonContent,
  IonIcon,
  IonLoading,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonText
} from '@ionic/react';
import React, { useCallback, useEffect, useState } from 'react';

import ButtonContainer from '@/components/common/buttonContainer/ButtonContainer';
import Logo from '@/components/common/logo/Logo';
import Padding from '@/components/common/padding/Padding';
import Spacer from '@/components/common/spacer/Spacer';
import { useDentist } from '@/contexts/DentistContext';
import { updateDentist } from '@/data/Dentists';
import useToast from '@/hooks/useToast';
import { arrowBackOutline, checkmarkCircleOutline } from 'ionicons/icons';

import './UpdatePatientVerification.css';

interface UpdatePatientVerificationProps {
  isOpen: boolean;
  onClose: () => void;
  presentingElement?: HTMLElement | undefined;
}

const UpdatePatientVerification: React.FC<UpdatePatientVerificationProps> = ({
  isOpen,
  onClose,
  presentingElement
}) => {
  const displayToast = useToast();
  const { selectedDentist: dentist, setSelectedDentist } = useDentist();

  const [patientVerificationEnabled, setPatientVerificationEnabled] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (dentist) {
      setPatientVerificationEnabled(
        dentist.patient_verification_enabled || false
      );
    }
  }, [dentist?.patient_verification_enabled]);

  const copy = {
    subHeader: 'PATIENT VERIFICATION',
    header: (
      <>
        Update <IonText color="primary">patient</IonText> verification settings
      </>
    ),
    message: `Please select whether patients of Dr. ${dentist?.first_name} should verify their identity via OTP.`,
    buttonText: 'Update',
    selectLabel: 'Require Verification via OTP',
    selectPlaceholder: 'Select verification method'
  };

  const onNext = useCallback(async () => {
    if (dentist?.id) {
      try {
        setLoading(true);
        await updateDentist({
          dentistId: dentist?.id,
          dentist: {
            ...dentist,
            patient_verification_enabled: patientVerificationEnabled
          }
        });
        setSelectedDentist({
          ...dentist,
          patient_verification_enabled: patientVerificationEnabled
        });
        setLoading(false);
        displayToast({
          message: 'Patient verification settings updated successfully.',
          duration: 3000,
          position: 'bottom',
          positionAnchor: 'tabBar'
        });
        onClose();
      } catch {
        setLoading(false);
        displayToast({
          message: 'Something went wrong. Please try again.',
          duration: 3000,
          position: 'bottom',
          positionAnchor: 'tabBar'
        });
      }
    }
  }, [patientVerificationEnabled, dentist?.id, updateDentist, onClose]);

  useEffect(() => {
    return () => {
      setLoading(false);
    };
  }, []);

  return (
    <IonModal
      isOpen={isOpen}
      onDidDismiss={onClose}
      presentingElement={presentingElement}
    >
      <IonContent>
        <Padding withSafeArea={!presentingElement}>
          <div id="updatePatientVerification">
            <Logo leftIcon={arrowBackOutline} onClickLeftIcon={onClose} />
            <Spacer top={0} bottom={24}>
              <h4 id="subHeader">{copy.subHeader}</h4>
              <h1 id="header">{copy.header}</h1>
              <p id="message">{copy.message}</p>
            </Spacer>

            <Spacer bottom={124}>
              <div id="form">
                <div className="input">
                  <IonSelect
                    label={copy.selectLabel}
                    labelPlacement="stacked"
                    placeholder={copy.selectPlaceholder}
                    value={patientVerificationEnabled}
                    onIonChange={(e: CustomEvent) =>
                      setPatientVerificationEnabled(e.detail.value)
                    }
                  >
                    <IonSelectOption value={true}>Yes</IonSelectOption>
                    <IonSelectOption value={false}>No</IonSelectOption>
                  </IonSelect>
                </div>
              </div>
            </Spacer>

            <ButtonContainer>
              <IonButton
                id="nextButton"
                onClick={onNext}
                expand="block"
                fill="solid"
                size="default"
                color="primary"
                strong={true}
                disabled={loading}
              >
                <IonIcon slot="start" icon={checkmarkCircleOutline}></IonIcon>
                {copy.buttonText}
              </IonButton>
            </ButtonContainer>
          </div>
        </Padding>
        <IonLoading isOpen={loading} message="Loading..." spinner="crescent" />
      </IonContent>
    </IonModal>
  );
};

export default UpdatePatientVerification;
