export const formatName = (name: string | null | undefined) => {
  if (!name) return "";

  return name
    .trim()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .filter((word) => word !== "")
    .join(" ");
};

export const getPossessiveName = (name: string | undefined) => {
  if (!name) return "";
  return name.endsWith("s") ? `${formatName(name)}'` : `${formatName(name)}'s`;
};
