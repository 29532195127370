import {
  getRecord,
  getRecords,
  insertRecord,
  updateRecord,
} from "@/utils/helpers/data";
import { WeekDay } from "@/utils/types";

export interface DentistHour {
  id?: string;
  dentist_id?: string;
  day: WeekDay;
  start_time: string | null;
  end_time: string | null;
  open: boolean;
  created_at?: string;
  updated_at?: string;
}

/**
 * Creates a new dentist hour record.
 * @param dentistHour - The dentist hour data to insert.
 * @returns The created dentist hour or null if insertion failed.
 */
export const createDentistHour = (dentistHour: DentistHour) =>
  insertRecord<DentistHour>("dentist_hours", dentistHour);

/**
 * Updates an existing dentist hour record.
 * @param id - The ID of the dentist hour.
 * @param dentistHour - The updated dentist hour data.
 * @returns The updated dentist hour or null if update failed.
 */
export const updateDentistHour = async ({
  id,
  dentistHour,
}: {
  id: string | null;
  dentistHour: DentistHour;
}): Promise<DentistHour | null> => {
  if (!id) return null;
  return updateRecord<DentistHour>(
    "dentist_hours",
    "id",
    id,
    dentistHour,
  );
};

/**
 * Retrieves a dentist hour by its ID.
 * @param id - The ID of the dentist hour.
 * @returns The dentist hour or null if not found.
 */
export const getDentistHour = (id: string) =>
  getRecord<DentistHour>("dentist_hours", "iid", id);

/**
 * Retrieves all dentist hours associated with a specific dentist ID.
 * @param dentistId - The ID of the dentist.
 * @returns An array of dentist hours.
 */
export const getDentistHoursByDentistId = (dentistId: string) =>
  getRecords<DentistHour>("dentist_hours", "dentist_id", dentistId);
