import { useContext, useEffect, useState } from "react";
import { App as Capacitor } from "@capacitor/app";
import useToast from "./useToast";
import { NavContext } from "@ionic/react";
import { ROUTES } from "@/utils/routes";

const useBackButton = () => {
  const { routeInfo, goBack } = useContext(NavContext);
  const [backPressCount, setBackPressCount] = useState(0);
  const displayToast = useToast();

  useEffect(() => {
    const onBack = () => {
      if (
        routeInfo?.pathname === ROUTES.ROOT
      ) {
        if (backPressCount === 0) {
          displayToast({
            message: "Press back again to exit.",
            duration: 2000,
            position: "bottom",
          });
          setBackPressCount(1);

          setTimeout(() => {
            setBackPressCount(0);
          }, 2000);
        } else if (backPressCount === 1) {
          Capacitor.exitApp();
        }
      }
    };

    Capacitor.addListener("backButton", onBack);

    return () => {
      Capacitor.removeAllListeners();
    };
  }, [backPressCount, routeInfo, goBack]);

  return null;
};

export default useBackButton;
