import { IonIcon } from '@ionic/react';
import React from 'react';

import { close } from 'ionicons/icons';

import './ModalHeader.css';

interface ModalHeaderProps {
  title: string | React.ReactNode;
  onClose: () => void;
}

const ModalHeader: React.FC<ModalHeaderProps> = ({ title, onClose }) => {
  return (
    <div id="modalHeader">
      <h1 id="modalHeaderTitle">{title}</h1>
      <IonIcon icon={close} onClick={onClose} id="closeIcon" />
    </div>
  );
};

export default ModalHeader;
