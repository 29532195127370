import { NavContext } from '@ionic/react';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';

import { useClinic } from './ClinicContext';
import { useAuth } from '@/contexts/AuthenticationContext';
import { useDentist } from '@/contexts/DentistContext';
import { getClinic, getClinicByMobileNumber } from '@/data/Clinics';
import {
  Dentist,
  getDentistsByMobileNumber,
  updateDentist
} from '@/data/Dentists';
import useToast from '@/hooks/useToast';
import { SIGN_IN_STEPS } from '@/utils/constants';
import { ROUTES } from '@/utils/routes';
import { supabase } from '@/utils/supabase';

interface SignInContextProps {
  loading: boolean;
  currentStep: string;
  setCurrentStep: React.Dispatch<React.SetStateAction<string>>;
  goBack: () => void;
  mobileNumber: string | null;
  setMobileNumber: React.Dispatch<React.SetStateAction<string | null>>;
  sendOtp: () => Promise<void>;
  resendOtp: () => Promise<void>;
  verifyOtp: (otp: string) => Promise<void>;
}

const SignInContext = createContext<SignInContextProps | undefined>(undefined);

interface SignInProviderProps {
  children: React.ReactNode;
}

export const SignInProvider: React.FC<SignInProviderProps> = ({ children }) => {
  const { navigate, goBack } = useContext(NavContext);
  const { authenticated, user, signIn } = useAuth();
  const { selectDentist } = useDentist();
  const displayToast = useToast();

  const [loading, setLoading] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<string>(
    SIGN_IN_STEPS.MOBILE_NUMBER
  );
  const [mobileNumber, setMobileNumber] = useState<string | null>(null);

  const onDone = useCallback(async () => {
    selectDentist(null);
    navigate(ROUTES.SELECT_DENTIST);
  }, [navigate, selectDentist]);

  const sendOtp = useCallback(async () => {
    setLoading(true);
    if (mobileNumber && authenticated && `63${mobileNumber}` === user?.phone) {
      displayToast({
        message: 'Skipped verification.',
        duration: 2400,
        position: 'bottom'
      });

      await signIn(false);
      await onDone();
      setLoading(false);
    } else {
      try {
        const clinic = await getClinicByMobileNumber(mobileNumber);
        const dentists = await getDentistsByMobileNumber(mobileNumber);

        if (!clinic && dentists.length === 0) {
          setLoading(false);
          displayToast({
            message:
              'No clinic or dentist found with this mobile number. Please sign up.',
            duration: 5000,
            position: 'bottom'
          });
        } else {
          const { error } = await supabase.functions.invoke('otp', {
            body: {
              operation: 'send',
              payload: {
                phone_number: `63${mobileNumber}`
              }
            }
          });

          if (error) {
            displayToast({
              message: `Error sending OTP: ${error.message}`,
              duration: 5000,
              position: 'bottom'
            });
            throw error;
          }

          setLoading(false);
          setCurrentStep(SIGN_IN_STEPS.VERIFICATION);
        }
      } catch {
        setLoading(false);
        displayToast({
          message: 'Please check your internet connection and try again.',
          duration: 5000,
          position: 'bottom'
        });
      }
    }
    setLoading(false);
  }, [mobileNumber, authenticated, user, signIn, onDone]);

  const resendOtp = useCallback(async () => {
    setLoading(true);
    await supabase.functions.invoke('otp', {
      body: {
        operation: 'resend',
        payload: {
          phone_number: `63${mobileNumber}`
        }
      }
    });
    setLoading(false);
  }, [mobileNumber]);

  const verifyOtp = useCallback(
    async (otp: string) => {
      try {
        setLoading(true);
        const {
          data: { user },
          error
        } = await supabase.auth.verifyOtp({
          phone: `63${mobileNumber}`,
          token: otp,
          type: 'sms'
        });

        if (error) {
          throw new Error(`Error verifying OTP: ${error.message}`);
        }

        if (!user) {
          throw new Error(
            'No clinic or dentist found with this mobile number. Please sign up.'
          );
        }

        const dentistsByMobileNumber =
          await getDentistsByMobileNumber(mobileNumber);
        if (dentistsByMobileNumber.length > 0) {
          const userRecord = await signIn();

          if (userRecord) {
            const updatePromises = dentistsByMobileNumber.map((dentist) =>
              dentist.id
                ? updateDentist({
                    dentistId: dentist.id,
                    dentist: { ...dentist, user_id: userRecord.id }
                  })
                : null
            );

            await Promise.all(updatePromises);
          }

          await onDone();
          setLoading(false);
          return;
        }

        const clinic = await getClinic(user.id);
        if (!clinic) {
          throw new Error(
            'No clinic or dentist found with this mobile number. Please sign up.'
          );
        }

        await signIn(false);
        await onDone();
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
        displayToast({
          message: error.message,
          duration: 5000,
          position: 'bottom'
        });
      }
    },
    [mobileNumber, signIn, onDone]
  );

  useEffect(() => {
    return () => {
      setLoading(false);
    };
  }, []);

  return (
    <SignInContext.Provider
      value={{
        loading,
        currentStep,
        setCurrentStep,
        goBack,
        mobileNumber,
        setMobileNumber,
        sendOtp,
        resendOtp,
        verifyOtp
      }}
    >
      {children}
    </SignInContext.Provider>
  );
};

export const useSignIn = () => {
  const context = useContext(SignInContext);
  if (context === undefined) {
    throw new Error('useSignIn must be used within a SignInProvider');
  }
  return context;
};
