// useToast.ts
import { useIonToast } from '@ionic/react';
import { useCallback } from 'react';

import { alertCircleOutline } from 'ionicons/icons';

// Toast
const useToast = () => {
  const [toast] = useIonToast();
  const displayToast = useCallback(
    ({
      message,
      duration = 2500,
      position = 'bottom',
      positionAnchor = undefined
    }: {
      message: string;
      duration?: number;
      position?: 'bottom' | 'top' | 'middle';
      positionAnchor?: string;
    }) => {
      toast({
        message,
        duration,
        position,
        positionAnchor,
        icon: alertCircleOutline,
        color: 'dark',
        mode: 'ios'
      });
    },
    [toast]
  );

  return displayToast;
};

export default useToast;
