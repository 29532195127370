import { IonIcon, IonImg } from '@ionic/react';
import React, { ReactNode } from 'react';

import Spacer from '@/components/common/spacer/Spacer';

import './Promo.css';

interface PromoProps {
  icon?: string;
  iconColor?: string;
  image?: string | null;
  imageStyle?: React.CSSProperties;
  header?: ReactNode | string;
  subHeader?: ReactNode | string;
}

const Promo: React.FC<PromoProps> = ({
  icon,
  iconColor = 'primary',
  image,
  imageStyle = { maxWidth: 286 },
  header,
  subHeader
}) => {
  const bloop = (e: React.MouseEvent<HTMLIonImgElement, MouseEvent>) => {
    const imageElement = e.currentTarget;
    if (imageElement.classList.contains('bloop')) {
      return;
    }
    imageElement.classList.add('bloop');
    setTimeout(() => {
      imageElement.classList.remove('bloop');
    }, 1000);
  };

  return (
    <div className="promo">
      {icon && !image && (
        <IonIcon className="icon" icon={icon} color={iconColor} />
      )}
      {!icon && image && (
        <IonImg
          className="image"
          src={image}
          style={imageStyle}
          onClick={bloop}
        />
      )}
      {header && (
        <Spacer top={42} bottom={16}>
          <h1 className="header headerText">{header}</h1>
        </Spacer>
      )}
      {subHeader && <h3 className="subHeader">{subHeader}</h3>}
    </div>
  );
};

export default Promo;
