import { PushNotifications, Token } from "@capacitor/push-notifications";

import { uploadNotificationToken } from "@/data/Users";

export const registerNotifications = async (userRecordId: string | null) => {
  PushNotifications.addListener("registration", (token: Token) => {
    uploadNotificationToken(userRecordId, token.value);
  });

  let permStatus = await PushNotifications.checkPermissions();

  if (permStatus.receive === "prompt") {
    permStatus = await PushNotifications.requestPermissions();
  }

  if (permStatus.receive !== "granted") {
    return;
  }

  await PushNotifications.register();
};
