import { IonIcon, IonImg } from '@ionic/react';
import React from 'react';

import logo from '@/assets/images/logo/logo-with-icon.png';

import './Logo.css';

interface LogoProps {
  leftIcon?: string | null;
  onClickLeftIcon?: () => void;
}

const Logo: React.FC<LogoProps> = ({
  leftIcon,
  onClickLeftIcon = () => {}
}) => {
  const bloop = (e: React.MouseEvent<HTMLIonImgElement, MouseEvent>) => {
    const imageElement = e.currentTarget;
    if (imageElement.classList.contains('bloop')) {
      return;
    }
    imageElement.classList.add('bloop');
    setTimeout(() => {
      imageElement.classList.remove('bloop');
    }, 1000);
  };

  return (
    <div id="logoContainer">
      {leftIcon && <IonIcon icon={leftIcon} onClick={onClickLeftIcon} />}
      <IonImg src={logo} id="logo" onClick={bloop} />
    </div>
  );
};

export default Logo;
